import React from "react";
import { Link } from "react-router-dom";
import iconImg from '../../images/Icon.png'
import tick from '../../images/check.png'
import cross from '../../images/delete.png'
import yellow from '../../images/Rectangle 762.png'
import green from '../../images/green strip.png'
import { useState } from 'react';



const Plans = () => {

    return (
        <>
            <div className="mt-16 relative min-h-screen">

                <div className="hidden md:block absolute -z-50 left-0 top-32 ">
                    <img className="w-6 h-[32rem]" src={yellow} alt="" />
                </div>

                <div className="hidden md:block absolute -z-50 right-0 ">
                    <img className="w-7 h-[28rem]" src={green} alt="" />
                </div>

                <div className="text-center">
                    <p className="text-3xl lg:text-4xl"> Find the <span className="text-[#EBBF1C]"> best plan </span> for you </p>
                </div>

                <div className="lg:mx-56 mx-6 mt-6 lg:mt-8">
                    <p className=" text-center  text-lg ">Transform your ideas into reality with our
                        <span className="font-semibold text-gray-800"> Virtual Photoshoot </span> solution.
                        Choose from our three exciting plans tailored to fit your needs:
                        <span className="font-semibold text-gray-800"> Starter, Booster
                            <span className="font-medium"> & </span>  Scale Up</span> </p>
                </div>

                <div className="flex flex-col mx-6 lg:mx-0 lg:flex-row justify-center mt-16 gap-10 " >

                    {/* first card*/}
                    <ul className="border-2 rounded-xl shadow-lg p-10  ">
                        <li className="mt-4">
                            <p className="text-xl text-center absolute z-10 mt-[-70px] px-6 py-2 rounded-md font-normal text-white bg-[#394A58]">Starter Plan</p>
                        </li>

                        <li className="mt-[15%]">
                            <li className="flex flex-row-reverse justify-end gap-2 mt-4 border-b-2 pb-2 border-gray-300">
                                <p>10% Discount</p>

                                <img className="w-6 h-6" src={tick} alt="" />

                            </li>
                            <li className="flex flex-row-reverse justify-end gap-2 mt-4 border-b-2 pb-2 border-gray-300">
                                <p>Premium Poses</p>
                                <img className="w-6 h-6" src={tick} alt="" />
                            </li>
                            <li className="flex flex-row-reverse justify-end gap-2 mt-4 border-b-2 pb-2 border-gray-300">
                                <p>Priority Delivery</p>
                                <img className="w-6 h-6" src={cross} alt="" />

                            </li>

                            <ul className="flex flex-row gap-24 mt-8 ">
                                <li className="mt-2">
                                    <span className="font-bold"> ₹199</span>/mo
                                </li>
                                <li className="">
                                    <Link to="https://play.google.com/store/apps/details?id=com.rhizobee.appliviu" target="_blank">
                                        <button className=" bg-[#394A58] text-white rounded-md p-2">
                                            Buy Now
                                        </button>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                    </ul>

                    {/* second card*/}
                    <ul className="border-2 rounded-xl shadow-lg p-10  ">

                        <li className="mt-4">
                            <p className="text-xl text-center absolute z-10 mt-[-70px] px-6 py-2 rounded-md font-normal text-white bg-[#394A58]">Booster Plan</p>
                        </li>

                        <li className="mt-[15%]">
                            <li className="flex flex-row-reverse justify-end gap-2 mt-4 border-b-2 pb-2 border-gray-300">
                                <p>15% Discount</p>

                                <img className="w-6 h-6" src={tick} alt="" />

                            </li >
                            <li className="flex flex-row-reverse justify-end gap-2 mt-4 border-b-2 pb-2 border-gray-300">
                                <p>Premium Poses</p>
                                <img className="w-6 h-6" src={tick} alt="" />
                            </li>
                            <li className="flex flex-row-reverse justify-end gap-2 mt-4 border-b-2 pb-2 border-gray-300">
                                <p>Priority Delivery</p>
                                <img className="w-6 h-6" src={cross} alt="" />

                            </li>

                            <ul className="flex flex-row gap-24 mt-8 ">
                                <li className="mt-2">
                                    <span className="font-bold"> ₹499</span>/mo
                                </li>
                                <li className="">
                                    <Link to="https://play.google.com/store/apps/details?id=com.rhizobee.appliviu" target="_blank">
                                        <button className=" bg-[#394A58] text-white rounded-md p-2">
                                            Buy Now
                                        </button>
                                    </Link>
                                </li>
                            </ul>
                        </li>



                    </ul>

                    {/* third card*/}

                    <ul className="border-2 rounded-xl shadow-lg p-10  ">
                        <li className="mt-4">
                            <p className="text-xl  text-center absolute z-10 mt-[-70px] px-6 py-2 rounded-md font-normal text-white bg-[#394A58]">Scale Up Plan</p>
                        </li>



                      <li className="mt-[15%]">
                      <li className="flex flex-row-reverse justify-end gap-2 mt-4 border-b-2 pb-2 border-gray-300">
                            <p>20% Discount</p>

                            <img className="w-6 h-6" src={tick} alt="" />

                        </li>
                        <li className="flex flex-row-reverse justify-end gap-2 mt-4 border-b-2 pb-2 border-gray-300">
                            <p>Premium Poses</p>
                            <img className="w-6 h-6" src={tick} alt="" />
                        </li>
                        <li className="flex flex-row-reverse justify-end gap-2 mt-4 border-b-2 pb-2 border-gray-300">
                            <p>Priority Delivery</p>
                            <img className="w-6 h-6" src={tick} alt="" />

                        </li>

                        <ul className="flex flex-row gap-24 mt-8 ">
                            <li className="mt-2">
                                <span className="font-bold"> ₹999</span>/mo
                            </li>
                            <li className="">
                                <Link to="https://play.google.com/store/apps/details?id=com.rhizobee.appliviu" target="_blank">
                                    <button className=" bg-[#394A58] text-white rounded-md p-2">
                                        Buy Now
                                    </button>
                                </Link>
                            </li>
                        </ul>
                      </li>

                    </ul>
                </div>

                

                <div className="lg:mx-56 mx-7 mt-14 lg:mt-14">
                    <p className=" text-center  text-lg ">
                        Our each plan offers unique features and benefits designed to elevate your virtual photoshoot experience.
                        Explore the plans now to streamline your photoshoot process and maximize efficiency.

                    </p>
                </div>

            </div>


        </>
    );
};





export default Plans;