import React, { useState, useEffect, useRef } from 'react';
import logo from '../../images/NewLogo.png';
import { LuMenu } from 'react-icons/lu';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from 'react-router-dom';
import img1 from '../../images/Header Frame 283.png';
import img2 from '../../images/Header Frame 282.png';
import img3 from '../../images/Header Demo Frame 283 .png';
import img4 from '../../images/Header Demo Frame 282.png';

const Header = () => {
  const handleViewMoreClick = () => {
    window.scrollTo({
      top: 0,
    });
  };

  let Links = [
    { name: 'Home', link: '/' },
    { name: 'Products', link: '/OurProducts' },
    { name: 'About', link: '/aboutus' },
    { name: 'Plans', link:'/plansVP'},
    { name: 'Contact', link: '/contactus' },
  ];



  const [productDropdownOpen, setProductDropdownOpen] = useState(false);
  const [demoDropdownOpen, setDemoDropdownOpen] = useState(false);

 
  const closeDropdownOnOutsideClick = (event) => {
    const isInsideProductDropdown =
      productDropdownRef.current &&
      productDropdownRef.current.contains(event.target);

    const isInsideDemoDropdown =
      demoDropdownRef.current && demoDropdownRef.current.contains(event.target);

    if (!isInsideProductDropdown && !isInsideDemoDropdown) {
      setProductDropdownOpen(false);
      setDemoDropdownOpen(false);
    }
  };

  const productDropdownRef = useRef(null);
  const demoDropdownRef = useRef(null);

  useEffect(() => {
    if (productDropdownOpen || demoDropdownOpen) {
      document.addEventListener('click', closeDropdownOnOutsideClick);
    }

    return () => {
      document.removeEventListener('click', closeDropdownOnOutsideClick);
    };
  }, [productDropdownOpen, demoDropdownOpen]);

  const [isOpen, setIsOpen]= useState(false);
  
  const handleButtonClick = () => {
    handleViewMoreClick();
    setIsOpen(false); 
  };

  return (
    <div className='shadow-xl w-full h-16 sticky top-0 z-20 left-0'>

      <div className='lg:flex items-center justify-between h-[4.5rem]  bg-white py-4 px-7 lg:px-10 '>
        <div className='text-xl lg:text-2xl font-semibold text-gray-800 cursor-pointer flex items-center gap-2 '>
          <Link to="/" onClick={handleButtonClick}>
            <img src={logo} alt='logo' className='w-10 lg:ml-6 lg:w-[60px] lg:p-[1px]' />
          </Link>
          <Link to="/" onClick={handleButtonClick}>
            <span>AppliViu</span>
          </Link>
        </div>

         
        <div onClick={()=>setIsOpen(!isOpen)} className='absolute right-8 text-3xl top-5 cursor-pointe lg:hidden w-7 h-7'>
          {isOpen ? <AiOutlineCloseCircle /> : <LuMenu />}
        </div>
        <ul
          className={`lg:flex lg:items-center mr-6 absolute lg:static bg-white  lg:z-auto z-[-1] left-0 w-full  lg:w-auto lg:pl-0 pl-9 transition-all duration-500 ease-in 
          ${isOpen ? 'top-14':'top-[-690px]'}`}
        >

          {Links.map((link) => (
            <li
              key={link.name}
              className='ml-2 lg:ml-6 lg:my-0 my-7 text-xl '
              onClick={()=>setIsOpen(false)}

            >


              {link.name === 'Products' ? (
                <div
                  onMouseEnter={() => setProductDropdownOpen(true)}
                  onMouseLeave={() => setProductDropdownOpen(false)}
                  onClick={() => setProductDropdownOpen(false)}
                  ref={productDropdownRef}
                  className='relative group '
                >
                  <Link to={link.link} onClick={handleViewMoreClick}>
                  <button className='product-dropdown-button flex text-gray-800 hover:text-gray-600 focus:outline-none '>
                    {link.name}
                    <div className='mt-[0.4rem] ml-[0.2rem] hidden lg:block'>
                      <IoIosArrowDown />
                    </div>
                  </button>
                  </Link>
                  {productDropdownOpen && (

                    //product drop down menu tailwind CSS



                    <div className='hidden lg:block product-dropdown-menu absolute right-[0px]  w-[40rem] h-[19.6rem]'>

                      <div className='bg-opacity-95 mt-8 drop-shadow-2xl space-x-1.5 space-y-2 bg-gray-100 text-black rounded-xl p-5 flex-col'>
                        <div className='ml-2 flex space-x-2'>
                          <div className='border-l-4 border-gray-500'>
                          </div>
                          <p className='text-xl'>
                            Products
                          </p>
                        </div>

                        <div className='flex gap-3 mt-2 pb-2'>
                          <Link to='/virtualAiPhotoshoot' className='flex-shrink-0'>
                            <img 
                              src={img1}
                              alt=''
                              className=' mt-2 w-[18rem] h-[14rem] rounded-xl hover:scale-[1.01] hover:drop-shadow-2xl hover:transform-gpu ease-in-out duration-300'
                            />
                          </Link>
                          <Link to='/virtualTrial' className='flex-shrink-0'>
                            <img
                              src={img2}
                              alt=''
                              className=' mt-2 w-[18rem] h-[14rem] rounded-xl hover:scale-[1.01] hover:drop-shadow-2xl hover:transform-gpu ease-in-out duration-300'
                            />
                          </Link>
                        </div>
                      </div>


                    </div>
                  )}
                </div>
              ) : (
                <Link to={link.link} onClick={handleViewMoreClick} className='text-gray-800'>
                  {link.name}
                </Link>
              )}
            </li>
          ))}
          <li
            className='lg:ml-8 lg:my-0 my-7 text-lg font-semibold'
            onMouseEnter={() => setDemoDropdownOpen(true)}
            onMouseLeave={() => setDemoDropdownOpen(false)}
            onClick={() => setDemoDropdownOpen(false)}

            ref={demoDropdownRef}
          >
            <div className=' relative group'>
              <Link to="/Demo" onClick={handleViewMoreClick}> 
              <button
                onClick={()=>setIsOpen(false)}
                className=' demo-dropdown-button btn flex text-xl font-medium rounded duration-500 lg:static'
              >
                Demo
                <div className=' hidden lg:block mt-[0.3rem] ml-[0.2rem]'>

                  <IoIosArrowDown />
                </div>
              </button>
              </Link>

              {demoDropdownOpen && (
                <div className=' hidden lg:block product-dropdown-menu absolute right-[0px] mt- w-[40rem] h-[20rem] '>

                  <div className='bg-opacity-81 mt-6 drop-shadow-2xl space-x-1.5 space-y-2 bg-gray-100 text-black rounded-xl p-5 flex-col'>
                    <div className='ml-2 flex space-x-2'>

                      <div className='border-l-4 border-gray-500'>
                      </div>
                      <p className='text-xl font-normal'>
                        Demo
                      </p>
                    </div>

                    <div className='flex gap-3 mt-2 pb-2'>
                      <Link to='/DemoVP' onClick={handleViewMoreClick} className='flex-shrink-0'>
                        <img
                          src={img3}
                          alt=''
                          className=' mt-2 w-[18rem] h-[14rem] rounded-xl hover:scale-[1.01] hover:drop-shadow-2xl hover:transform-gpu ease-in-out duration-300'
                        />
                      </Link>

                      <Link to='/DemoVT' onClick={handleViewMoreClick} className='flex-shrink-0'>
                        <img
                          src={img4}
                          alt=''
                          className='mt-2 w-[18rem] h-[14rem] rounded-xl hover:scale-[1.01] hover:drop-shadow-2xl hover:transform-gpu ease-in-out duration-300'
                        />
                      </Link>
                    </div>

                  </div>


                </div>
              )}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
