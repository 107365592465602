import React from 'react';
import { FiCamera } from 'react-icons/fi';
import { FaRegClock } from 'react-icons/fa';
import { BiMoneyWithdraw } from 'react-icons/bi';
import { FaPersonRunning } from 'react-icons/fa6';

export const Card = () => {
  return (
    <div className='flex flex-col lg:flex-row gap-3 mt-10 mx-16 justify-evenly lg:mx-24 lg:mt-0 lg:gap-10'>

      <div className=" flex flex-col justify-center items-center p-6 bg-white shadow-lg rounded-lg">
        <div className="text-6xl text-red-600 mb-3">
          <FaRegClock />
        </div>
        <div className='text-center max-w-[230px]'>
          <h5 className="mb-2 text-2xl font-semibold text-black text-center">SAVES TIME</h5>
          <p className="mb-3 text-xl text-black text-center">Photoshoot and try-on clothes within few minutes</p>
        </div>
      </div>

      <div className=" flex flex-col justify-center items-center p-6 bg-white shadow-lg rounded-lg">
        <div className="text-6xl text-green-600 mb-3">
          <BiMoneyWithdraw />
        </div>
        <div className='text-center max-w-[230px]'>
          <h5 className="mb-2 text-2xl font-semibold text-black text-center">SAVES MONEY</h5>
          <p className="mb-3 text-xl text-black text-center">Increase profit margin and positive impact on balance sheet</p>
        </div>
      </div>

      <div className=" flex flex-col justify-center items-center p-6 bg-white shadow-lg rounded-lg">
        <div className="text-6xl text-sky-400 mb-3">
          <FaPersonRunning />
        </div>
        <div className='text-center max-w-[230px]'>
          <h5 className="mb-2 text-2xl font-semibold text-black text-center">SAVES EFFORT</h5>
          <p className="mb-3 text-xl text-black text-center">Easily attract shoppers to explore brand products</p>
        </div>
      </div>

    </div>
  )
}
