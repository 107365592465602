import React from 'react'
import img from "../../images/Vector.png"
import video from "../../video/virtualVid.mp4"

const TextPage = () => {
  return (
    <div className="relative">
      <div className="hidden lg:block absolute right-0 top-[3rem]">
        <img src={img} alt="yellow" className="w-12 h-[400px]" />
      </div>
      <div className="flex flex-col-reverse justify-evenly items-center mx-5 my-6 lg:flex-row lg:py-3">
        <div className="text-justify pt-10 lg:pt-0">
          <h1 className="text-2xl lg:text-4xl  text-black lg:my-4">Transform your <span className='text-[#EBBF1C]'>cloth</span></h1>
          <h1 className="text-2xl lg:text-4xl	 text-[#EBBF1C] lg:my-4">image into a captivating</h1>
          <h1 className="text-2xl lg:text-4xl	 text-black lg:my-4"><span className='text-[#EBBF1C]'>photo-shoot </span>in few clicks</h1>
        </div>
        <div className=' lg:w-[38%] lg:h-[38%] md:w-[90%] rounded-3xl overflow-hidden'>
          <video src={video} autoPlay loop muted />
        </div>
      </div>
    </div>



    
  )
}

export default TextPage