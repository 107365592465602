import React from 'react'
import Intro from './Intro'
import Description from './Description'
import CoreValues from './CoreValues'
import { Helmet } from 'react-helmet-async'


const AboutUs = () => {
  return (
    <>

      <Helmet>
        <title > AppliViu | Creating a reality where trial rooms come to you </title>
        <meta name='description' content="We aim to collaborate with artificial intelligence and machine
        learning to propose a 3D Avatar model to enhance the shopping experience and 
        help captivate shoppers' attention to online portals."/>

        <meta
          name="keywords"
          content="Appliviu, Virtual Trial, Virtual Photoshoot, 3D Avatar, virtual photoshoot appliviu, E-commerce PhotoShoot, Virtual try-on, 
          virtual photoshoot app, virtual photoshoot ideas, ai virtual photoshoot, shutter virtual photoshoot, virtual assistant photoshoot, 
          Photoshoot Assistant virtually, modelverse, model verse, virtual photo meaning , adobe virtual photography, virtual photography meaning
          3d model verse, model photshoot virutally "
        />

        <link rel="canonical" href="/aboutus" />

      </Helmet>

      <div>

        <Intro />
        <Description />
        <CoreValues />

      </div>
    </>


  )
}

export default AboutUs