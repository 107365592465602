import React from "react";
import { GoDotFill } from 'react-icons/go';
import Carousel from './Carousel'



const GrayBoxText = () => {
  let slides = [
    require('../../images/Property 1=Default (1).png'),
    require('../../images/Property 1=Default (2).png'),
    require('../../images/Property 1=Default.png'),
  ];



  return (
    <div className=" bg-[#C5DCCA] my-14 pb-6 lg:my-24 h-full w-full shadow-gray-300 shadow-2xl rounded-lg">

      <div className="flex flex-col lg:flex-row">

        <div className=" basis-1/2">

          <div className=" bg-gray-100 shadow-gray-400 shadow-2xl rounded-md lg:rounded-full max-w-[100%] w-[80%] lg:w-[85%] mx-10 px-20 lg:px-0 lg:mx-36 ">
            <Carousel slides={slides} />
          </div>

        </div>



        <div className="basis-1/2 lg:mx-36  lg:ml-44">
          <div className="pt-12  lg:pt-[14%]">
            <h1 className="text-left text-xl mx-10 lg:text-4xl md:mx-32 lg:mx-0">
              Digitally create photo realistic and accurate images for your
              clothes in just few clicks.
            </h1>
          </div>
          <div className="flex-col ml-[2.5rem] mt-6 pb-10 md:ml-[15rem] lg:ml-0 lg:mt-10 space-y-3">
            <div className="flex justify-start items-center gap-1">
              <GoDotFill className="text-[0.7rem]" />
              <h2 className="text-sm lg:text-2xl">Choose any background of your choice</h2>
            </div>
            <div className="flex justify-start items-center gap-1">
              <GoDotFill className="text-[0.7rem]" />
              <h2 className="text-sm lg:text-2xl">Photorealistic and accurate image</h2>
            </div>
            <div className="flex justify-start items-center gap-1">
              <GoDotFill className="text-[0.7rem]" />
              <h2 className="text-sm lg:text-2xl">Image accepted across all platforms</h2>
            </div>
            <div className="flex justify-start items-center gap-1">
              <GoDotFill className="text-[0.7rem]" />
              <h2 className="text-sm lg:text-2xl">Works on both Android and iOS</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrayBoxText;
