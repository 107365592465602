import React from "react";
import img from "../../images/Rectangle 22.png";
import { Link } from "react-router-dom";

const Appliviu1 = () => {
  const handleViewMoreClick = () => {
    window.scrollTo({
      // behavior: "smooth",
      top: 0
    });
  };

  return (
    <div className="hidden lg:block">
      <div className=" flex flex-col-reverse lg:flex-row lg:mx-20 lg:justify-start lg:gap-10 relative">
        <div className="lg:ml-[4rem]">
          <img className="lg:h-[500px] lg:w-[450px]" src={img} alt="video" />
        </div>


        <div className="lg:w-1/2 text-justify mx-10 lg:py-6 lg:px-4 lg:mb-14 ">
          <h1 className="text-4xl mb-2 lg:text-2xl  first-letter:mb-6"> AppliViu's</h1>
          <h1 className="text-4xl mb-2 lg:text-5xl  first-letter:mb-6"> Virtual Trial</h1>
          <h2 className="text-2xl mb-2 lg:text-3xl text-gray-500 lg:mb-3">
            Changing the way People
            Try Clothes</h2>
          <p className="text-lg pb-6 lg:pb-6 text-gray-500">
            Virtual Trial(VT) Solution by AppliViu offers an immersive, online shopping
            experience. Using cutting-edge augmented reality, customers can
            virtually try on clothing in real-time. This interactive platform
            enhances customer confidence, reduces returns, and boosts sales.
            Seamlessly integrating with e-commerce websites, our solution
            revolutionizes the way consumers shop, providing a personalized and
            engaging experience from the comfort of their homes.
          </p>
          <div className="flex justify-center lg:justify-start gap-5 lg:gap-[40px]">

            <Link to="/DemoVT" onClick={handleViewMoreClick}>

              <button class="text-medium w-[120px] lg:w-[150px] border-2 bg-transparent hover:bg-gray-500 text-gray-700 font-semibold  hover:text-white py-2 px-4 border-gray-800 hover:border-transparent rounded-full">
                DEMO
              </button>
            </Link>
            <Link to="/virtualTrial" onClick={handleViewMoreClick}>
              <button class="text-medium  border-2 bg-transparent hover:bg-gray-500 text-gray-700 font-semibold  hover:text-white py-2 px-4 border-gray-800 hover:border-transparent rounded-full">
                VIEW MORE
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appliviu1;
