import React, { useState } from "react";
import img from "../../images/Vector.png";
import img2 from "../../images/ContactUS.png";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    phone: '',
    countryRegion: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the data to be sent
    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      companyName: formData.companyName,
      phone: formData.phone,
      country: formData.countryRegion,
      message: formData.message,
    };

    // Prepare the headers with the authentication token
    const headers = {
      Authorization: "AppLiViuQuery",
      "Content-Type": "application/json",
    };

    // Send a POST request to the provided endpoint
    fetch("http://64.227.148.23:3001/api/common/send-query", {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          // Successful submission, you can handle the response here
          console.log("Form submitted successfully");
        } else {
          // Handle errors, for example, by displaying an error message to the user
          console.error("Form submission failed");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };
  return (
    <div className="flex flex-col-reverse lg:flex-row mt-14 mb-16 lg:mb-28 lg:mx-20 justify-evenly realtive">
    <div className="hidden lg:block mr-auto -z-10 absolute top-[160px] left-0">
      <img className="h-[500px] w-[28px]  " src={img} alt="yellow"/>
    </div>

    <div className="flex flex-col-reverse lg:flex-col space-y-8 ">
      <div className="flex justify-center mx-4 mt-6 md:mx-20 lg:mx-10 lg:max-w-xl">
        <img className="w-full h-full object-cover" src={img2} alt="image"/>
      </div>
     <div className="mx-10 md:mx-20 lg:mx-0 lg:ml-10 lg:mr-[12rem]">
        <p className="text-yellow-400 text-sm md:text-xl text-justify">We are committed to serving our customers’ needs and 
        expectations and adding value to their commendations.<br/>
        <span className="text-black">Customer satisfaction is our happiness.</span> </p>
        
    </div> 
    </div>
    <div className="mx-3 md:mx-20 lg:mx-0 lg:w-[41%] rounded-md bg-[#c5dcca]">
      <form onSubmit={handleSubmit} className="space-y-5 p-4 mt-5">
        <div className="flex space-x-7">
          <div className="w-72">
            <label htmlFor="firstName" className=" text-lg ">
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="Enter First Name"
              className=" pl-2 block w-full h-12  rounded-md"
            />
          </div>
          <div className="w-72">
            <label htmlFor="lastName" className="block text-lg ">
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Enter Last Name"
              className=" pl-2 block w-full h-12  rounded-md"
            />
          </div>
        </div>

        <div className="flex space-x-7">
          <div className="w-72">
            <label htmlFor="email" className="block text-lg  text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter email address"
              className=" pl-2 block w-full h-12  rounded-md"
            />
          </div>
          <div className="w-72">
            <label htmlFor="companyName" className="block text-lg  text-gray-700">
              Company Name
            </label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              placeholder="Enter your Company Name"
              className=" pl-2 block w-full h-12  rounded-md"
            />
          </div>
        </div>

        <div className="flex space-x-7">
          <div className="w-72">
            <label htmlFor="phone" className="block text-lg  ">
              Phone Number
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Enter Name"
              className=" pl-2 block w-full h-12  rounded-md"
            />
          </div>
          <div className="w-72">
            <label htmlFor="countryRegion" className="block text-lg ">
              Country/Region
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Enter Name"
              className=" pl-2 block w-full h-12  rounded-md"
            />
          </div>
        </div>

        <div>
          <label htmlFor="message" className="block text-lg ">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            
            className="pl-2 block w-full h-28  rounded-md"
          />
        </div>

        <div>
          <button
            type="submit"
            className="bg-[#f0f0f0] mt-1 pl-2 text-xl w-full h-12 rounded-md"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
    </div>
    
      
  );
};

export default ContactUs;
