import React from "react";
import videobg from "../../video/Vid mp4 (1).webm"
import videophone from "../../video/Introdunction_video.mp4"

import img1 from "../../images/Frame 13.png";
import img2 from "../../images/Frame 15.png";
import vid3 from "../../video/phoneRotation.mp4";


const Introduction = () => {
  return (
    <div className="relative">


      <div className="mx-10 mt-10 lg:mt-0 lg:mx-36">
        <p className="pt-16 pb-12 text-sm lg:text-lg lg:py-10 text-justify text-gray-600	">
          AppliViu, a tech company, revolutionizing the retail industry with its
          cutting-edge AI-driven solutions. One of its flagship offerings is the
          AI Photoshoot solution, streamlining the process of capturing
          high-quality apparel images. Additionally, AppliViu presents an
          innovative Virtual Trial Room, a platform that enables shoppers to try
          on apparel virtually, reducing returns and enhancing customer
          satisfaction. By leveraging advanced artificial intelligence, AppliViu
          empowers retailers to save time and resources, ensuring efficient and
          cost-effective operations. Embracing the future of retail, AppliViu
          sets new standards in convenience, efficiency, and shoppers’
          experience.
        </p>
      </div>
      <div className="mx-10 lg:flex gap-6 lg:mx-[12rem] ">

        <div className=" basis-1/2">

          <div className=" lg:w-[41rem]  overflow-hidden lg:rounded-3xl">
            <video className="rounded-2xl mb-10 lg:mb-0 lg:top-30 lg:left-64  " src={videobg} autoPlay loop muted />
          </div>

        </div>


        <div className=" basis-1/2">

          <div className=" flex flex-col space-y-2 lg:space-y-0 lg:my-6 lg:w-[50%] ">
            <div className="  lg:mb-2 lg:w-[450px] rounded-2xl  overflow-hidden">
              <video src={videophone} autoPlay loop muted />
            </div>
            <div className="lg:mb-2 lg:w-[450px] rounded-2xl overflow-hidden">
              {/* <img className=" lg:h-[288px] lg:w-[420px] " src={img3} alt="images hai" />*/}
              <video src={vid3} autoPlay loop muted />
            </div>
          </div>

        </div>

      </div>
    </div>
  );
};

export default Introduction;
