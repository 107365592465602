import React from "react";
import img from "../../images/AIimages.png";

const Ecomm = () => {

  return (
    <div className="flex flex-col lg:flex-row justify-center lg:gap-6 lg:my-10">

      <div className="lg:basis-1/2 lg:ml-36">
        <div className="px-10 pb-10 lg:pb-0 lg:py-12">
        <h1 className="text-3xl lg:text-5xl pb-2 lg:pb-4">E-Commerce</h1>
        <h2 className="text-2xl lg:text-4xl lg:pb-4">AI Automated Imaging</h2>
        <p className="lg:text-xl text-justify pr-10 border-8 border-l-[#C5DCCA] border-y-0 border-r-0 pl-4 mt-4">
          In e-commerce, consumers rely heavily on visuals to make
          purchasing decisions, and their expectations for high-quality
          product photos are increasing. By streamlining processes, this
          innovative platform provides rapid access to high-quality photos
          at a fraction of the typical costs. Gone are the days of enduring
          time constraints and labor-intensive efforts. AppliViu redefines
          the photography experience by making it more efficient,
          cost-effective, and accessible to a broader audience.
        </p>
        </div>
      </div>

      <div className="md:hidden lg:block lg:basis-1/2 lg:mr-24 ">
        <img className="w-[98%] ml-1 lg:ml-0" src={img} alt="" />
      </div>

    </div>
  );
};

export default Ecomm;
