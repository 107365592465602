import React from "react";

import img1 from "../../images/Rectangle 741  (2).png";
import img2 from "../../images/Rectangle 741  (1).png";
import img3 from "../../images/Rectangle 741  (4).png";
import img4 from "../../images/Rectangle 741  (3).png";

const UseApp = () => {
  return (
    <div className="my-10 ">
      <div>
        <div className="text-4xl mx-auto text-center lg:text-left lg:font-semibold pb-16 lg:pl-36">
          <h1>How to use the app ?</h1>
        </div>
        
        <div className="flex flex-col lg:flex-row justify-evenly  lg:mx-28 gap-10">

          <div className="basis-1/4 ">
            <img className="w-[60%] lg:w-[80%] mx-20 lg:mx-8" src={img1} alt="" />
            <h2 className="text-lg mx-28 lg:mx-8 text-center lg:font-semibold mt-2 lg:mt-4">
              Select model and pose of choice.
            </h2>
          </div>

          <div className="basis-1/4 ">
            <img className="w-[60%] lg:w-[80%] mx-20 lg:mx-8" src={img2} alt="" />
            
            <h2 className="text-lg mx-28 lg:mx-8 text-center lg:font-semibold mt-2 lg:mt-4">
              Capture the pattern (Front and Back)
            </h2>
            
          </div>

          <div className="basis-1/4 ">
            <img className="w-[60%] lg:w-[80%] mx-20 lg:mx-8" src={img3} alt="" />
            <h2 className="text-lg mx-28 lg:mx-8 text-center lg:font-semibold mt-2 lg:mt-4 ">
              Once done add item to cart.
            </h2>
          </div>

          <div className="basis-1/4">
            <img className="w-[60%] lg:w-[80%] mx-20 lg:mx-8" src={img4} alt="" />
            <h2 className="text-lg mx-28 lg:mx-8 text-center lg:font-semibold mt-2 lg:mt-4">
              Select model and pose of choice.
            </h2>
          </div>
        </div>
      </div>



    </div>
  );
};

export default UseApp;
