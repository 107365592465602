import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img4 from "../../images/businessman1.png"
import img3 from "../../images/man.png"
import img2 from "../../images/girl.png"
import img1 from "../../images/bussiness-man.png"

import "./main.css";

const Testimonial = () => {
    let data = [
        { name: "my name", img: img1, desc: "AppliViu has been a lifesaver for building my apparel portfolio on a budget. The image quality is really good just like expensive photoshoots, and their meticulous attention to detail is truly remarkable. I'm genuinely impressed by their technology." },
        { name: "my name", img: img2, desc: "AppliViu has greatly simplified my life. Traditional photoshoots can be quite costly. The images provided by Appliviu are of high quality. They always ensure that the resulting images meet the quality standards for various e-commerce platforms." },
        { name: "my name", img: img3, desc: "I've been an AppliViu customer for the past 6 months and their budget-friendly apparel images helped me alot in saving cost on expensive photoshoots. Their high-quality images are easily accepted by e-commerce platforms. " },
        { name: "my name", img: img4, desc: "I have recently started my business with Men's apparels. AppliViu is a lifesaver for my apparel portfolios. Their exceptional image quality is great and now no need to go for expensive photoshoots. Also, their after-sale service is truly commendable." },
    ];

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 100,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <>

            <div className="lg:mx-32 lg:mb-20 hello">
                <p className="text-2xl lg:text-4xl mx-8  mb-10 lg:mb-14 pl-4 py-1 border-l-4 border-purple-600">
                    Customer Testimonials
                </p>

                <div className=" ">
                    <Slider {...settings}>
                        {data.map((item, index) => (
                            <div key={index} className="bg-blue-100 px-10 pt-5 pb-8 lg:shadow-xl">
                                <img className="w-14 h-14" src={`${item.img}`} alt="" />
                                <p className="pt-4 text-sm">{item.desc}</p>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>

        </>
    );
};

export default Testimonial;
