import React from "react";
import { Link } from "react-router-dom";
import Rb from '../../images/Rb.png';
import applogo from '../../images/getitonplaystore.png'
import { AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import { BsLinkedin } from "react-icons/bs";

const Footer = () => {
  const handleViewMoreClick = () => {
    window.scroll({
      top: 0
    });
  };

  return (



    <div className="mt-28 ">

      {/*<div className="block lg:hidden">
     <div className="justify-start ml-1 top-12 z-50 relative ">
        <div >
          <Link to="/" >
            <img src={Rb} alt='logo' className='w-32 h-28' />
          </Link>
        </div>
      </div>
     </div>*/}
     
      <div className="flex flex-col lg:space-y-6 bg-slate-800 text-white pt-6  pb-10 lg:pt-10 ">

        <div className="flex flex-col lg:flex-row lg:gap-[55rem]">
          
          <div className="flex justify-center item-center lg:justify-start lg:mx-[6rem] ">
            <Link to="/" >
              <img src={Rb} alt='logo' className='w-20 h-18' />
            </Link>

            <div className="grid place-items-center	text-left">
            <p>Rhizobee<br />Innovations</p>
          </div>
          </div>

          <div className="hidden lg:block mt-4">
            <Link to="https://play.google.com/store/apps/details?id=com.rhizobee.appliviu" target="_blank">
            <img className="h-10" src={applogo} alt="" />
            </Link>
          </div>  

        </div>

        <div className="">
          <p className="border-b-2 mt-6 lg:mt-0"></p>
        </div>

        <div className="flex flex-col-reverse text-xl lg:flex-row lg:space-x-40 justify-center lg:text-md ">

          <div className="flex justify-center space-x-8 lg:ml-0 lg:flex-row lg:space-x-40">
            <ul className="flex flex-col space-y-1 text-left">
              <li className="pb-1 pt-5 lg:pt-0 text-sm lg:text-base font-semibold">
                <p>COMPANY</p>
              </li>

              <li className="text-sm lg:text-base hover:text-sky-400">
                <Link to="/" onClick={handleViewMoreClick}>
                  <button>• Home</button>
                </Link>
              </li>
              <li className="text-sm lg:text-base hover:text-sky-400">
                <Link to="/aboutus" onClick={handleViewMoreClick}>
                  <button>• About Us</button>
                </Link>
              </li>
              <li className="text-sm lg:text-base hover:text-sky-400">
                <Link to="/contactus" onClick={handleViewMoreClick}>
                  <button>• Contact Us</button>
                </Link>
              </li>
            </ul>
            <div className="hidden lg:block">
              <ul className="flex flex-col space-y-1 text-left	">
                <li className="pb-1 text-base font-semibold">
                  <p>PRODUCTS</p>

                </li>
                <li className=" text-sm lg:text-base hover:text-sky-400">
                  <Link to="/virtualAiPhotoshoot" onClick={handleViewMoreClick}>
                    <button>• AppliViu VP</button>
                  </Link>
                </li>
                <li className="text-sm lg:text-base hover:text-sky-400">
                  <Link to="/virtualTrial" onClick={handleViewMoreClick}>
                    <button>• AppliViu VT</button>
                  </Link>
                </li>
              </ul>
            </div>
            <ul className="flex flex-col pt-5 lg:pt-0 space-y-1 text-left	">
              <li className="pb-1 font-semibold text-sm lg:text-base">
                <p>RESOURCES</p>
              </li>
              <li className="text-sm lg:text-base hover:text-sky-400">
                <Link to="/PrivacyPolicy" onClick={handleViewMoreClick}>
                  <button>• Privacy Policy</button>
                </Link>
              </li>
              <li className="text-sm lg:text-base hover:text-sky-400">
                <Link to="/TermsAndConditions" onClick={handleViewMoreClick}>
                  <button>• Terms Of Use</button>
                </Link>
              </li>

            </ul>

          </div>

          <div className="flex flex-col my-4 lg:my-0 ">

            <div className="hidden lg:block" >
              <p className="mb-3 text-left text-base">
                Follow Us On:
              </p>
            </div>

            <div className="flex space-x-10 lg:space-x-3 pt-2 lg:pt-0 lg:pl-0 justify-center lg:justify-start">

              <Link to="https://instagram.com/appliviu?igshid=NGVhN2U2NjQ0Yg==" target="_blank" >
                <AiFillInstagram className="w-8 h-8 lg:w-6 lg:h-6" />
              </Link>

              <AiFillYoutube className="w-8 h-8 lg:w-6 lg:h-6" />

              <Link to="https://www.linkedin.com/company/rhizobee-innovations" target="_blank">
                <BsLinkedin className="w-7 h-7 lg:w-6 lg:h-6" />
              </Link>
            </div>
            <div className="block lg:hidden">
              <p className="border-b-2 pt-6 "></p>
            </div>

            <div className="hidden lg:block">
              <p className="mt-3 text-base">
                AppliViu @{ }
                {new Date().getFullYear()}
              </p>
            </div>

          </div>



        </div>

        <div className="block lg:hidden mt-8 border-t-2 pt-5">
            <div className="flex flex-col items-center">
            <div><p className=" text-base">Get the AppliViu app</p></div>
            <Link to="https://play.google.com/store/apps/details?id=com.rhizobee.appliviu" target="_blank">
            <img className="h-10 mt-4" src={applogo} alt="" />
            </Link>
            </div>
        </div>  

      </div>

    </div>

  );
};

export default Footer;
