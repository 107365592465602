import React from "react";
import DemoVideoVP from "./DemoVideoVP";
import DemoCardsVP from "./DemoCardsVP";
import { Helmet } from "react-helmet-async";

const DemoVP = () => {
    return (
        <>
            <Helmet>
                <title>AppliViu | Demo Virtual Photoshoot</title>
                <meta name="description" content="Checkout AppliViu product demo and contact us for more details." />

                <meta
                    name="keywords"
                    content="Appliviu, Virtual Trial, Virtual Photoshoot, 3D Avatar, virtual photoshoot appliviu, E-commerce PhotoShoot, Virtual try-on, 
                    virtual photoshoot app, virtual photoshoot ideas, ai virtual photoshoot, shutter virtual photoshoot, virtual assistant photoshoot, 
                    Photoshoot Assistant virtually, modelverse, model verse, virtual photo meaning , adobe virtual photography, virtual photography meaning
                    3d model verse, model photshoot virutally "
                />

                <link rel="canonical" href="/DemoVP" />

            </Helmet>

            <div>
                <DemoVideoVP />
                <DemoCardsVP />
            </div>
        </>

    )
}

export default DemoVP