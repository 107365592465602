import React from "react";
import DemoVideoVT from "./DemoVideoVT";
import DemoCardsVT from "./DemoCardsVT";
import { Helmet } from "react-helmet-async";

const DemoVT = () => {
    return (

        <>
            <Helmet>
                <title>AppliViu | Demo Virtual Trial</title>
                <meta name="description" content="Checkout AppliViu product demo and contact us for more details." />

                <meta
                    name="keywords"
                    content="Appliviu, Virtual Trial, Virtual Photoshoot, 3D Avatar, virtual photoshoot appliviu, E-commerce PhotoShoot, Virtual try-on, 
                    virtual photoshoot app, virtual photoshoot ideas, ai virtual photoshoot, shutter virtual photoshoot, virtual assistant photoshoot, 
                    Photoshoot Assistant virtually, modelverse, model verse, virtual photo meaning , adobe virtual photography, virtual photography meaning
                    3d model verse, model photshoot virutally "
                />

                <link rel="canonical" href="/DemoVT" />
            </Helmet>


            <div>
                <DemoVideoVT />
                <DemoCardsVT />
            </div>
        </>

    )
}

export default DemoVT