import React, { useState } from "react";
import shirt1 from "../../images/df 1.png";
import shirt2 from "../../images/asf.png";
import shirt3 from "../../images/jhjk.png";
import strip from "../../images/greenStrip.png";
import mockup1 from "../../images/S1-01.png";
import mockup2 from "../../images/S2-01.png";
import mockup3 from "../../images/S3-01.png";

const View = () => {
  const [activeShirt, setActiveShirt] = useState(1);

  const handleShirtClick = (shirtNumber) => {
    setActiveShirt(shirtNumber);
  };

  return (
    <div className="mt-10 mb-[48rem] sm:mb-[55rem] lg:mt-0 pt-10 lg:mb-[40rem]">
      <div className="relative ">
        <div className="bg-[#c3c2c2] mx-6 lg:mx-0 top-[200px] sm:top-[300px] h-[400px] rounded-3xl lg:rounded-l-[100px] lg:h-[480px] lg:w-[1250px] shadow-2xl shadow-gray-500 absolute sm:left-10 lg:top-0 lg:left-[16.7rem] mt-12 -z-10">
          <h1 className="text-5xl pt-44 ml-20 lg:text-6xl lg:ml-[740px] lg:pt-[50px]">360 View</h1>
          <div className="border-8 border-l-[#7c7c7c] border-y-0 border-r-0 max-w-xl ml-12 lg:ml-[650px]">
            <p className="text-xl md:text-2xl pl-3 lg:pl-5 mt-4 lg:py-3">
              Virtual fitting room for the most realistic shopping experience
              ever with 360 views.
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row left-[17rem] top-10 bg-white border-2 border-black rounded-[20px] absolute sm:left-[30rem] lg:top-[19rem] lg:left-[59rem] z-10">
          <div>
            <img className="h-[120px] sm:h-[160px] cursor-pointer" src={shirt1} alt="shirt" onClick={() => handleShirtClick(1)} />
          </div>
          <div>
            <img className="h-[120px] sm:h-[160px] cursor-pointer" src={shirt2} alt="shirt" onClick={() => handleShirtClick(2)} />
          </div>
          <div>
            <img className="h-[120px] sm:h-[160px] cursor-pointer" src={shirt3} alt="shirt" onClick={() => handleShirtClick(3)} />
          </div>
        </div>
        <div className="absolute top-8 sm:left-28 lg:left-[25rem] lg:top-12 ">
          {activeShirt === 1 && <img className="lg:max-w-full w-[60%] sm:w-[45%] lg:w-[28%]" src={mockup3} alt="mockup" />}
          {activeShirt === 2 && <img className="lg:max-w-full w-[60%] sm:w-[45%] lg:w-[28%]" src={mockup2} alt="mockup" />}
          {activeShirt === 3 && <img className="lg:max-w-full w-[60%] sm:w-[45%] lg:w-[28%]" src={mockup1} alt="mockup" />}
        </div>
        <div className="hidden lg:block absolute top-[650px] left-[88rem] -z-20">
          <img className="h-[500px]" src={strip} alt="green" />
        </div>
      </div>
    </div>
  );
};

export default View;
