import React from 'react'
import img from '../../images/Aboutus.png'
import img2 from '../../images/blueBg.png'


const Intro = () => {
  return (
    <>
      <div className="flex flex-col lg:flex-row justify-center relative">
        <div className="lg:basis-1/2 lg:max-w-3xl mx-10 mt-10 lg:ml-32 lg:mt-28">
          <div className="text-left md:text-center lg:mt-20">
            <h1 className="text-2xl lg:text-4xl text-center  text-black m-2 ">Elevating the shopping experience by bringing
              <span className='text-yellow-300'> <br/>trial rooms</span> and
              <span className='text-yellow-300'> apparel photoshoots </span> to
              the <br/> doorstep with our <span className='text-yellow-300'><br/> Artificial Intelligence </span>
              Tech
            </h1>
            <h1 className="text-2xl lg:text-4xl   m-2"></h1>
            <h1 className="text-2xl lg:text-4xl  text-yellow-300 m-2"></h1>
            <h1 className="text-2xl lg:text-4xl  text-black m-2"></h1>
          </div>
        </div>
        <div className=" lg:basis-1/2 mx-6 my-6 md:mx-20 lg:ml-28 lg:mt-32">
          <img src={img} alt="banner" className=' ' />
        </div>
       
        <div className='hidden lg:block absolute -z-10 right-0 top-8'>
          <img src={img2} alt="bgblue" className="h-[480px] w-[320px] " />
        </div>
      </div>

      <div className='bg-[#C5DCCA] lg:text-start flex items-center justify-center py-10 px-10 my-6 lg:py-16 lg:px-40 lg:mt-20'>
        <div className=" pl-3 border-l-4 border-yellow-300 ">
          <p className='text-justify lg:text-xl'>We focus on presenting our customers with an eccentric and unparalleled platform to attract shoppers from all
            over the world. We are here to help our customers reach new pinnacles and help them increase their annual
            revenue via our distinctive features.</p>
        </div>

      </div>
    </>
  )
}

export default Intro