import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './header/Header';
import Footer from './footer/Footer';

const Layout = () => {
  const location = useLocation();

  useEffect(() => {
    // Save the scroll position in the browser's session storage
    sessionStorage.setItem(`scrollPosition_${location.pathname}`, window.scrollY);
  }, [location.pathname]);

  useEffect(() => {
    // Scroll to the saved position when navigating back
    const savedScrollY = sessionStorage.getItem(`scrollPosition_${location.pathname}`) || 0;
    window.scrollTo(0, savedScrollY);
  }, [location.pathname]);

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
