import React from "react";
import img from "../../images/Rectangle 733 (1).png";
import { Link } from "react-router-dom";

const Appliviu2 = () => {

    const handleViewMoreClick = () => {
        window.scrollTo({
            // behavior: "smooth",
            top: 0
        });
    };

    return (
        <div className=" block lg:hidden">
            <div className=" text-left mx-10 mt-16 ">
                <h1 className="text-lg  "> AppliViu's</h1>

                <h1 className="text-2xl mb-2 font-semibold "> Virtual Photoshoot</h1>
                <h2 className="text-lg mb-2  text-gray-500">
                    Say Goodbye to traditional Photoshoot
                </h2>
            </div>

            <div className="mx-6 ">
                <img className=" " src={img} alt="img" />
            </div>

            <div className=" text-justify mx-10 ">
                <p className="pb-5  lg:py-5 text-gray-500">
                    Our Virtual Photoshoot(VP) Solution redefines the way of apparel
                    photoshoots. It renders apparel photos on model and provides
                    high-quality, realistic images without the need of physical
                    photoshoots. This innovative technology not only saves businesses time
                    and resources but also ensures visually appealing product displays.
                    Experience the future of e-commerce with our seamless and efficient
                    virtual photography solution.
                </p>
                <div className="flex justify-center flex-wrap lg:justify-start gap-3 lg:gap-[40px]">

                    <Link to="/DemoVP" onClick={handleViewMoreClick}>
                        <button class="text-medium w-[120px]  lg:w-[150px] border-2 bg-transparent hover:bg-gray-500 text-gray-700 font-semibold  hover:text-white py-2 px-4 border-gray-800 hover:border-transparent rounded-full">
                            DEMO
                        </button>
                    </Link>
                    <Link to="/virtualAiPhotoshoot" onClick={handleViewMoreClick}>
                        <button class="text-medium  border-2 bg-transparent hover:bg-gray-500 text-gray-700 font-semibold  hover:text-white py-2 px-4 border-gray-800 hover:border-transparent rounded-full">
                            VIEW MORE
                        </button>
                    </Link>
                    <Link to="/plansVP" onClick={handleViewMoreClick}>
                        <button class="text-medium  border-2 bg-transparent hover:bg-gray-500 text-gray-700 font-semibold  hover:text-white py-2 px-4 border-gray-800 hover:border-transparent rounded-full">
                            VIEW PLANS
                        </button>
                    </Link>
                </div>
            </div>

        </div>

    );
};

export default Appliviu2;
