import React from 'react'
import ContactUs from './ContactUs'
import Address from './Address'
import { Helmet } from 'react-helmet-async'

const ContactLayout = () => {
  return (
    <>
      <Helmet>
        <title > AppliViu | We are here because of you. Your satisfaction is our priority </title>
        <meta name='description' content='Customer satisfaction is our number one priority, 
        and we promise to deliver 24/7 service to all your queries. 
        We are dedicated to meeting the demands and standards of our clients.'/>


        <meta
          name="keywords"
          content="Appliviu, Virtual Trial, Virtual Photoshoot, 3D Avatar, virtual photoshoot appliviu, E-commerce PhotoShoot, Virtual try-on, 
          virtual photoshoot app, virtual photoshoot ideas, ai virtual photoshoot, shutter virtual photoshoot, virtual assistant photoshoot, 
          Photoshoot Assistant virtually, modelverse, model verse, virtual photo meaning , adobe virtual photography, virtual photography meaning
          3d model verse, model photshoot virutally "
        />

        <link rel="canonical" href="/contactus" />

      </Helmet>

      <div>
        <ContactUs />
        <Address />
      </div>
    </>

  )
}

export default ContactLayout