import React from "react";
import mockup from "../../images/GarmentFit.png";

const Garment = () => {
  return (
    <div>
      <div className="relative mb-[36rem] lg:mb-20">
        <div>
        <div className="bg-[#c3c2c2] mx-8 sm:mx-28 rounded-2xl lg:rounded-none lg:mx-0 lg:h-[490px] lg:w-[980px] shadow-2xl shadow-gray-500 absolute top-96 sm:top-[35rem]  lg:right-0 lg:top-20 z-10 pb-10">
          <h1 className="text-4xl text-center lg:text-left pt-40 lg:ml-[350px] lg:pt-[60px]">Garment <span className="text-[#898989]"> Fit Map</span></h1>
          <div className="max-w-xl lg:ml-[220px]">
            <p className="text-2xl text-center text-[#757575] pl-1  py-7">
              Bid Adieu To The <br /> "Guessing Game Of Outfit"
            </p>
          </div>
          <p className="text-center mx-5 text-2xl lg:text-left lg:mx-0 lg:ml-[250px]">Fit Maps shows the tightness of the garment</p>
          <div className="max-w-xl sm:text-center sm:ml-0 ml-8 lg:text-left lg:ml-[380px] mt-10">
            
            <p className="text-xl">
              <span className="font-bold text-black"> - Very Tight</span> appears
              in red
            </p>
            <p className="text-xl">
              <span className="font-bold text-black">- Tight </span>appears  in orange
            </p>
            <p className="text-xl">
              <span className="font-bold text-black">- Fit</span> appears in yellow
            </p>
            <p className="text-xl">
              <span className="font-bold text-black">- Normal</span> appears in green
            </p>
          </div>
        </div>

        </div>
        
        <div className="sm:ml-[8rem] lg:ml-[8rem] ">
          <img className="sm:w-[90%] lg:w-[35%] lg:pt-[1rem]"  src={mockup} alt="mockup" />
        </div>
      </div>
    </div>
  );
};

export default Garment;
