import React from "react";
import video from "../../video/AppliViuVP.mp4"


const DemoVideoVP =()=>{
    return(
        <div className="">
        <div className="px-20 mt-16 md:px-96 ">
        <div className="pb-6 md:pb-8 border-dashed border-t-4 border-[#C5DCCA]"></div>
        <p className="text-xl md:text-3xl text-center"> AI driven <span className="text-yellow-400" >virtual photoshoot </span> experience </p>
        <div className="pb-6 md:pb-8 border-dashed border-b-4 border-[#C5DCCA]"></div>
        </div>

        <div className="mt-16 md:mx-52">
        
        <video className="h-full w-full" src={video} controls/>
     </div>
    </div>
    )
}

export default DemoVideoVP