import React, { useState, useEffect } from "react";


function Carousel({ slides }) {
  const [current, setCurrent] = useState(0);

  // Fixed title and description
  //const fixedTitle = "AppliViu";
  //const fixedDescription = "Evolving your virtual shopping experience";

  // Function to handle auto-slide change
  const nextSlide = () => {
    setCurrent((prevCurrent) => (prevCurrent + 1) % slides.length);
  };

  // Automatically change slide every 3 seconds
  useEffect(() => {
    const interval = setInterval(nextSlide, 3000); // 3 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className=" overflow-hidden mt-10 py-4  lg:py-0 lg:mt-[-10%] lg:ml-[30%] lg:max-w-[40%] relative">
        <div
          className={`flex transition ease-out duration-400`}
          style={{
            transform: `translateX(-${current * 100}%)`,
          }}
        >
          {slides.map((s, index) => (
            <img key={index} src={s} alt={`Slide ${index + 1}`} />
          ))}
        </div>

        {/* <div className="absolute top-[4.6rem] left-52 sm:top-[120px] sm:left-[25rem] lg:top-[150px] lg:left-[700px] lg:text-left">
          <h1 className="text-2xl sm:text-6xl lg:text-7xl  text-white lg:m-3">{fixedTitle}</h1>
          <h2 className="sm:text-2xl lg:text-4xl  text-white lg:p-3">{fixedDescription}</h2>
        </div>
          */}

        {/* <div className="absolute top-0 h-full w-full flex justify-between items-center text-white px-10 text-2xl">
          <button onClick={previousSlide}>
            <BsFillArrowLeftCircleFill />
          </button>
          <button onClick={nextSlide}>
            <BsFillArrowRightCircleFill />
          </button>
      
        </div> */}
      </div>
      <div className="py-1 flex justify-center gap-3 w-full">
        {slides.map((_, i) => (
          <div
            onClick={() => {
              setCurrent(i);
            }}
            key={"circle" + i}
            className={`rounded-full lg:w-[10px] lg:h-[10px] cursor-pointer ${i === current ? "bg-gray-200" : "bg-gray-100"
              }`}
          ></div>
        ))}

      </div>
    </>
  );
}

export default Carousel;
