import React from "react";
import img from "../../images/Rectangle 22.png";
import { Link } from "react-router-dom";

const Appliviu1 = () => {
    const handleViewMoreClick = () => {
        window.scrollTo({
            // behavior: "smooth",
            top: 0
        });
    };

    return (
        <div className=" block lg:hidden">
            <div className=" text-left mx-10 mt-16 ">
                <h1 className="text-lg "> AppliViu's</h1>

                <h1 className="text-2xl mb-2 font-semibold">Virtual Trial</h1>
                <h2 className="text-lg mb-2  text-gray-500">
                    Changing the way People Try Clothes
                </h2>
            </div>
            <div className="mx-4">
                <img className="" src={img} alt="video" />
            </div>

            <div className=" text-justify mx-10 ">
                <p className="pb-5  lg:py-5 text-gray-500">
                    Our Virtual Trial(VT) Solution offers an immersive, online shopping
                    experience. Using cutting-edge augmented reality, customers can
                    virtually try on clothing in real-time. This interactive platform
                    enhances customer confidence, reduces returns, and boosts sales.
                    Seamlessly integrating with e-commerce websites, our solution
                    revolutionizes the way consumers shop, providing a personalized and
                    engaging experience from the comfort of their homes.
                </p>
                <div className="flex justify-center lg:justify-start gap-5 lg:gap-[40px]">
                    <Link to="/DemoVT" onClick={handleViewMoreClick}>

                        <button class="text-medium w-[120px] lg:w-[150px] border-2 bg-transparent hover:bg-gray-500 text-gray-700 font-semibold  hover:text-white py-2 px-4 border-gray-800 hover:border-transparent rounded-full">
                            DEMO
                        </button>
                    </Link>
                    <Link to="/virtualTrial" onClick={handleViewMoreClick}>
                        <button class="text-medium  border-2 bg-transparent hover:bg-gray-500 text-gray-700 font-semibold  hover:text-white py-2 px-4 border-gray-800 hover:border-transparent rounded-full">
                            VIEW MORE
                        </button>
                    </Link>
                </div>
            </div>
        </div>

    );
};

export default Appliviu1;
