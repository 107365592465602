import React from "react";
import img from "../../images/pinkStrip.png";


const Description = () => {
  return (
    <div className="realtive">
      <div className="top-[65rem]  -z-10 w-[150px] absolute">
        <img className="h-[45rem] lg:h-[30rem] " src={img} alt="pinkstrip " />
      </div>

      <div>
      <div className="flex flex-col mt-20 mx-10  lg:pt-10 lg:mx-40 ">
        <div className="flex flex-col lg:flex-row text-center gap-12 ">
          <div className="bg-[#FCFCFC] shadow-xl shadow-gray-400 lg:w-[50%] ">
            <p className="text-md mt-6 py-10 px-10 text-justify ">
            Our products promise to increase the profit margin and effectively have an 
            impact on the bottom and top line of the balance sheet of a company.
            </p>
          </div>
          <div className="bg-[#FCFCFC] shadow-xl shadow-gray-400 lg:w-[50%] ">
            <p className="text-md mt-6 py-10 px-10 text-justify">
            Attract and keep the shoppers tied up to the online platform, 
            leading them to explore the products more.
            </p>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row text-center gap-12  my-12">
        <div className="bg-[#FCFCFC] shadow-xl shadow-gray-400 lg:w-[50%]  ">
            <p className="text-md mt-6 py-10 px-10 text-justify ">
            Our AI-driven photoshoot solution enables retailers to save costs, 
            time, and effort required for apparel model photoshoots.
            </p>
          </div>
          <div className="bg-[#FCFCFC] shadow-xl shadow-gray-400 lg:w-[50%] ">
            <p className="text-md mt-6 py-10 px-10 text-justify ">
            Our virtual trial solution reduces the yearly sales returns and significantly decreases
            the rate of bracketing, leading to substantial cost savings for the retailers.
            </p>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Description;
