import React, { useState, useEffect } from "react";
import img from '../../images/Vector.png';
import { Card } from "./Card";
import 'react-circular-progressbar/dist/styles.css'; // Import circular progress bar styles
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const WhyBetter = () => {
    const [hovered, setHovered] = useState(false);
    const [progress, setProgress] = useState(0);
    const [progressText, setProgressText] = useState(0);

    const handleHover = () => {
        setHovered(true);
    };

    const handleLeave = () => {
        setHovered(false);
        setProgress(0);
        setProgressText(0);
    };

    useEffect(() => {
        if (hovered) {
            let interval = setInterval(() => {
                if (progress < 80) {
                    setProgress(progress + 1);
                    setProgressText(progressText + 1);
                }
            }, 20); // Adjust the interval duration for desired animation speed

            return () => clearInterval(interval);
        }
    }, [hovered, progress, progressText]);

    return (
        <div onLoad={handleHover}>
            <div className="flex-col lg:gap-3 text-black text-center mt-20 text-3xl mx-3 lg:mx-0 lg:text-5xl lg:mt-4">
                <p>
                    <span className="font-semibold">What advantages does </span>
                    <span className="font-bold">AppliViu</span>
                    {/* <span className="font-semibold"> better than an</span> */}
                </p>
                <p>
                    <span className="font-semibold">offer to </span>
                    <span className="font-bold">Apparel vendors?</span>
                </p>
            </div>

            <div className="lg:flex mt-4">
                <div className="hidden lg:block ml-0">
                    <img src={img} alt="yellow" className=" w-14 h-[400px]" />
                </div>
                
                <div className="lg:ml-[37%]">
                    <div className=" flex justify-center mt-20">
                        <div className="w-[150px] h-44">
                            <CircularProgressbar
                                value={progress}
                                text={`${progressText}%`}
                                styles={buildStyles({
                                    textSize: '20px',
                                    pathColor: `#9797fe`,
                                    textColor: '#090979',
                                })}
                                className="border-10 border-[#DEDEDE] rounded-full transform hover:translate-y-[-12px] transition-transform duration-300"
                            />
                        </div>
                    </div>
                    <div className="text-center text-2xl font-md mt-5 text-black text-md">
                        <p>Save Up to {progressText}% of Time</p>
                        <p>and Expenses!</p>
                    </div>
                </div>
            </div>

            <div>
                <Card />
            </div>
        </div>
    );
};

export default WhyBetter;
