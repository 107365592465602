import React from "react";
import banner from "../../images/DarkBlueBanner.png";
import banner2 from "../../images/div.elementor-widget-wrap.png"
import { Link } from "react-router-dom";
import { RiArrowRightDoubleFill } from 'react-icons/ri'

const TryOut = () => {

  const handleViewMoreClick = () => {
    window.scrollTo({
        // behavior: "smooth",
        top: 0
    });
};

  return (
    <div className="lg:mb-32">
      <div className="flex flex-col-reverse mx-8  lg:flex-row gap-10 justify-evenly items-center my-20 lg:mt-28 lg:mx-20 ">
        <div className="lg:ml-28">
          <h2 className="text-2xl pb-2 lg:p-3">Want to get started?</h2>
          <h1 className="text-3xl pb-2 lg:text-5xl lg:p-3">Try out AppliViu </h1>
          <h1 className="text-3xl lg:text-5xl lg:pl-3 font-extrabold" >today!</h1>
          <Link to="/contactus" onClick={handleViewMoreClick} >
            <button className="bg-[#233545] my-6 lg:ml-4 lg:my-8 flex justify-center items-center text-white w-44 h-14 rounded-md">
              Let's get started
              <div className="ml-3 pt-[2px]">
                <RiArrowRightDoubleFill />
              </div>
            </button>
          </Link>
        </div>
        <div className="lg:mr-18">
          <img className="hidden lg:block lg:w-[35rem]" src={banner} alt="banner" />
          <img className="block lg:hidden" src={banner2} alt="" />
        </div>
      </div>
    </div>
  );
};

export default TryOut;
