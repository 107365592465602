import React from "react";
import img from "../../images/handsome_tourist.jpg";
import img2 from "../../images/banner1.png"

const PhotoshootPage = () => {
  return (
    <div>
      <div className=" m-4 lg:mx-20 relative">
        <img className="hidden sm:block object-cover h-96 w-full m-auto mt-4 lg:h-[600px] lg:w-[100%] lg:mt-7"  src={img} alt="banner" />
        <img className="block sm:hidden object-cover h-96 w-full m-auto mt-4 " src={img2} alt="banner"/>
        <div className="absolute top-20 left-44 sm:top-[35%] sm:left-[50%] text-left">
          <h1 className="text-2xl sm:text-3xl lg:text-6xl  text-white p-1">AI photo-shoot</h1>
          <h2 className="text-xl sm:text-2xl lg:text-4xl  text-white p-1">by AppliViu</h2>
          <h2 className="text-xl sm:text-2xl lg:text-4xl  text-black p-1">
            The online presence you’ve <br/>been waiting for
          </h2>
        </div>
      </div>
      <div className="mx-6 md:mx-16 lg:mx-[12rem] text-justify">
        <p className="py-5 text-xl lg:text-2xl text-gray lg:py-10">
          Transforming the entire photo-shoot experience through the integration
          of AI technology offering numerous exciting opportunities to enhance
          both the creative and practical aspects of photography.
        </p>
      </div>
    </div>
  );
};

export default PhotoshootPage;
