import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className='my-4 p-4'>
        <h1 className='font-semibold mt-2'>PRIVACY POLICY</h1>
        <p className=' my-2'>
        This Privacy Statement describes how{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          Rhizobee Innovations Private Limited
        </a>{' '}
        and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          https://rhizobee.com
        </a>{' '}
        manage personal data and personal information, and respects your privacy. This policy may be amended from time to time. We encourage visitors to and users of our sites to regularly review our Privacy Policy.
      </p>
      <p>
        All "Personal Information" (which is defined as any information that identifies or can be used to identify, contact, or locate the person to whom such information pertains) that we collect and maintain will be subject to this Privacy Policy, as amended from time to time.
      </p>
        <h1 className='font-semibold my-2'>Collection of Personal Information</h1>
        <p className=' mt-2'>
        As a visitor to the Site, you can engage in many activities without providing any Personal Information.
      </p>
      <p className=' mt-2'>
        However, when you register to use a{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          Rhizobee Innovations Private Limited
        </a>{' '}
        and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          https://rhizobee.com
        </a>{' '}
        site and order products or services as a{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          Rhizobee Innovations Private Limited
        </a>{' '}
        and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          https://rhizobee.com
        </a>{' '}
        user/subscriber in order to provide the services to you, we may collect the following Personal Information from you:
      </p>
        <h1 className='font-semibold my-4'>How Your Personal Information Is Used</h1>
        <p className=' mt-2'>
        Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          https://rhizobee.com
        </a>{' '}
        collect your information in order to provide services to you, comply with our legal obligations, and to improve our products and services. We do not sell, rent or share your personally identifiable information to or with third parties in any way other than as disclosed in this Privacy Policy. Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          https://rhizobee.com
        </a>{' '}
        may use this information to:
      </p>
      <ul className="list-disc ml-20 mt-2">
        <li>
          Authenticate and provide access to the websites, mobile applications, and services.
        </li>
        <li>
          Process your financial transactions.
        </li>
        <li>
          Send you order / renewal confirmations.
        </li>
        <li>
          Register your rights, if any, to technical support or other benefits that may be made available to registered users.
        </li>
        <li>
          Respond to customer service requests, questions, and concerns.
        </li>
        <li>
          Administer your account.
        </li>
        <li>
          Send you requested product or service information.
        </li>
        <li>
          Keep you informed about special offers and services of{' '}
          <a href="https://rhizobee.com" className="text-blue-500">
            Rhizobee Innovations Private Limited
          </a>{' '}
          and/or{' '}
          <a href="https://appliviu.com" className="text-blue-500">
            https://appliviu.com
          </a>{' '}
          /{' '}
          <a href="https://rhizobee.com" className="text-blue-500">
            https://rhizobee.com
          </a>{' '}
          and selected third parties.
        </li>
        <li>
          Administer promotions and sweepstakes you enter and notify you of the results.
        </li>
        <li>
          Investigate, prevent, or take action regarding illegal activities and/or violations of our Terms of Service.
        </li>
        <li>
          Meet our research and product/service development needs and to improve our Site, services, and offerings.
        </li>
        <li>
          Customize your experience, including targeting our services, search results, and offerings to you.
        </li>
      </ul>
      <p className=' mt-2'>
        We store information that we collect through cookies, log files, transparent images, and/or third-party sources to create a summary of your preferences. We tie your personally identifiable information, and/or your membership usage history, to information in the summary, in order to provide tailored promotions and marketing offers, to improve the content of the site for you and/or to determine your preferences.
      </p>
      <p>
        In certain situations,{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          Rhizobee Innovations Private Limited
        </a>{' '}
        and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          https://rhizobee.com
        </a>{' '}
        may be required to comply with legal obligations to disclose personal data in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.
      </p>
      <p>
        Where required by law, and when we believe that disclosure is necessary to protect our rights, avoid litigation, protect your safety or the safety of others, investigate fraud, and/or respond to a government request. We may also disclose information about you in order to comply with our legal obligations if we determine that such disclosure should be made for reasons of national security, law enforcement, or other issues of public importance.
      </p> 


        <h1 className='font-semibold mt-2'>Information Sharing with Service Providers / Agents</h1>

 <p className="mb-4">
        Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        uses one or more outside credit card processing companies to bill you for our goods and services. To the best of our knowledge, these companies do not retain share, store or use personally identifiable information for any other purpose.
      </p>

      <p className="mb-4">
        We also share Personal Information with certain companies that perform services on our behalf. We only share the Personal Information which is necessary for them to perform those services. We require any company with which we may share Personal Information to protect that data in a manner consistent with this policy and to limit the use of such Personal Information to the performance of services for Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>
        . We do not sell or otherwise provide Personal Information to other companies for the marketing of their own products or services.
      </p>

        <h1 className='font-semibold mt-2'>Your Choice (not to use)</h1>
        <p className="mb-4">
        Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        do not sell your personal data or personal information to any third party. We do use third party advertising services to manage the advertisements that we place on other sites. Our third-party partner may use technologies such as cookies and trackers to gather information about your activities on this site and other sites in order to provide you advertising based upon your browsing activities and interests. Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        will only use your personal information for the purposes described in this Privacy Statement and will not use your personal information for a purpose that is materially different without your express authorization.
      </p>

      <p>
        If you use a Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        mobile app, we may send you push notifications from time-to-time in order to update you about any events or promotions that we may be running. If you no longer wish to receive these types of communications, you may turn them off at the device level.
      </p>
        
        <h1 className='font-semibold mt-2'>Protection of Your Personal Information</h1>

        <p className="mb-4">
        The Personal Information that you provide in connection with the use of the Site is protected in several ways.
      </p>
      <ul className="list-disc mb-4 ml-20">
        <li>
          Access by you to your account profile is available through a password and unique customer ID selected by you. This password is encrypted. You should strive to use a strong, alpha-numeric password which you should not divulge to anyone.
        </li>
        <li>
          Your Personal Information resides on secure servers that only selected Rhizobee Innovations Private Limited and/or{' '}
          <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://appliviu.com
          </a>{' '}
          /{' '}
          <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://rhizobee.com
          </a>{' '}
          personnel and contractors have access to via password.
        </li>
        <li>
          Your Personal Information is encrypted whenever it is transmitted to Rhizobee Innovations Private Limited and/or{' '}
          <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://appliviu.com
          </a>{' '}
          /{' '}
          <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://rhizobee.com
          </a>.
        </li>
        <li>
          When you enter sensitive information (such as credit card number) on our registration or order forms, we encrypt that information using transport layer security technology.
        </li>
      </ul>
      <p className="mb-4">
        We strive to protect the Personal Information submitted to us, both during transmission and once we receive it. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we take into account the sensitivity of the Personal Information we collect, process, and store, and the current state of technology to use these measures to protect your Personal Information, we cannot guarantee its absolute security.
      </p>

      <p>
        If you have any questions about security on our Web Sites, you can send an email to us at{' '}
        <a href="mailto:contact@rhizobee.com" className="text-blue-500">
          contact@rhizobee.com
        </a>.
      </p>
        <h1 className='font-semibold mt-2'>Cookie and Tracking Technologies</h1>
        <p className=' mt-2'>
        The Site may automatically collect information as you browse, such as Internet Service Provider, browser type and version, operating system and device type, average time spent on our site(s), pages viewed, information accessed, the Internet Protocol (IP) address used to connect your computer to the Internet, and other relevant statistics. Such information is sometimes referred to as web analytics and/or clickstream data.
      </p>
      <p className=' mt-2'>
        We may combine this automatically collected log information with other information we collect about you. We do this to improve services we offer you, to enhance marketing and analytics, and provide site functionality.
      </p>
      <p className=' mt-2'>
        Technologies such as cookies, beacons, tags, and scripts are used by{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          Rhizobee Innovations Private Limited
        </a>{' '}
        and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          https://rhizobee.com
        </a>{' '}
        and our affiliates, analytics services, and service providers to provide site features and functionality. These technologies are used in analyzing trends, administering the site, tracking users’ movements around the site, and gathering demographic information about our user base as a whole. We may receive reports based on the use of these technologies by these companies on an individual as well as aggregated basis.
      </p>
      <ul className="list-disc ml-14 mt-2">
        <li>
          A cookie is a small, unique text file that a website can send to your computer when you visit a site. We may use session cookies, which terminate when a user closes his or her browser, and/or persistent cookies, which remain on the user's computer until manually deleted. Most web browsers can either alert you to the use of cookies or refuse to accept cookies entirely. If you do not want us to deploy cookies to your browser, you can set your browser to reject cookies or to notify you when a website tries to put a cookie on your computer. Rejecting cookies may affect your ability to use the Site.
        </li>
        <li>
          We partner with a third party to either display advertising on our Web site or to manage our advertising on other sites. Our third-party partner may use technologies such as cookies to gather information about your activities on this site and other sites to provide you advertising based upon your browsing activities and interests.
        </li>
      </ul>

        <h1 className='font-semibold mt-2'>Changes in Privacy Policy</h1>
        <p className=' mt-2'>
        <a href="https://rhizobee.com" className="text-blue-500">
          Rhizobee Innovations Private Limited
        </a>{' '}
        and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          https://rhizobee.com
        </a>{' '}
        reserves the right to amend the Privacy Policy from time to time at its sole discretion and will provide notice by email or on the home page of the Site when we make material changes to this Privacy Policy prior to the change becoming effective.
      </p>
      <p className=' mt-2'>
        You will be deemed to have been made aware of, and will be subject to, any material changes to the Privacy Policy after such notice has been posted with the following exception: If at the time you provide Personal Information to{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          Rhizobee Innovations Private Limited
        </a>{' '}
        and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          https://rhizobee.com
        </a>{' '}
        you are given the opportunity (as you currently are) to limit how such information will be used to communicate with you, either by{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          Rhizobee Innovations Private Limited
        </a>{' '}
        and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          https://rhizobee.com
        </a>{' '}
        or by third parties,{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          Rhizobee Innovations Private Limited
        </a>{' '}
        and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          https://rhizobee.com
        </a>{' '}
        will not change your preference in this regard without your express consent. However, if{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          Rhizobee Innovations Private Limited
        </a>{' '}
        and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          https://rhizobee.com
        </a>{' '}
        is acquired by or merged with another entity,{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          Rhizobee Innovations Private Limited
        </a>{' '}
        and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          https://rhizobee.com
        </a>{' '}
        may provide to such entity customer information that is related to that part of our business that was sold to or merged with the other entity without your consent, but{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          Rhizobee Innovations Private Limited
        </a>{' '}
        and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          https://rhizobee.com
        </a>{' '}
        will provide notice of such asset sales, acquisitions, or mergers on this Site.
      </p>
        
        <h1 className='font-semibold mt-2'>Non-Discrimination</h1>
        <p className=' mt-2'>
        <a href="https://rhizobee.com" className="text-blue-500">
          Rhizobee Innovations Private Limited
        </a>{' '}
        and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          https://rhizobee.com
        </a>{' '}
        will not discriminate against any user. We will not deny services from users who exercise rights under a privacy law, and we do not charge different prices to users who choose to exercise rights under applicable privacy law.
      </p>
        <h1 className='font-semibold mt-2'>Links to third party sites</h1>
        <p>
        Our Site includes links to other Web sites whose privacy practices may differ from those of{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          Rhizobee Innovations Private Limited
        </a>{' '}
        and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          https://rhizobee.com
        </a>
        . If you submit personal information to any of those sites, your information is governed by their privacy policies. We encourage you to carefully read the privacy policy of any Web site you visit.
      </p>
        <h1 className='font-semibold mt-2'>Referral Program Emails</h1>
        <p className=' mt-2'>
        If you choose to use our referral service to tell a colleague about our Site, share a Collection, or invite someone to join a team account, we will ask you for your colleague's name and email address. We will automatically send your colleague a one-time email inviting him or her to visit the Site.{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          Rhizobee Innovations Private Limited
        </a>{' '}
        and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        stores this information for the sole purpose of sending this one-time email, tracking the success of our referral program, and managing access to Collections.
      </p>
      <p>
        Your colleague may contact{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          Rhizobee Innovations Private Limited
        </a>{' '}
        and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        to request that we remove this information from our database.
      </p>
        <h1>Children</h1>
        <p>
        We do not intend to solicit or collect Personal Information from anyone under the age of 18. If you are under 18, do not enter information on this site or engage our services. If you believe a child of yours under the age of 18 has entered Personal Information, please contact us at{' '}
        <a href="mailto:contact@rhizobee.com" className="text-blue-500">
          contact@rhizobee.com
        </a>{' '}
        to have the data removed and terminate the child's account.
      </p>
      <p>
        We have appointed a data protection officer (DPO) for the Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          https://rhizobee.com
        </a>{' '}
        who is responsible for overseeing questions in relation to this privacy notice. If you have any questions, concerns, or requests about this privacy notice, how we manage your Personal Information, or any other issue relating to your Personal Information, please contact our DPO at{' '}
        <a href="mailto:contact@rhizobee.com" className="text-blue-500">
          contact@rhizobee.com
        </a>
        .
      </p>
        <h1 className='font-semibold'>Privacy Complaints</h1>
        <p>
        Individuals have certain statutory rights in relation to their personal data. Subject to any exemptions provided by law, you may have the right to request access to Personal Data, seek to update, delete or correct this Information, and request to restrict further processing of your Personal Data.
      </p>
      <p>
        If you wish to make any other objection to the processor of your personal data, you may also object to processing by emailing{' '}
        <a href="mailto:contact@rhizobee.com" className="text-blue-500">
          contact@rhizobee.com
        </a>
        .
      </p>
      <p>
        <a href="https://rhizobee.com" className="text-blue-500">
          Rhizobee Innovations Private Limited
        </a>{' '}
        and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          https://rhizobee.com
        </a>{' '}
        takes its users' privacy concerns seriously. If you have any questions about this Privacy Policy or if you believe that{' '}
        <a href="mailto:contact@rhizobee.com" className="text-blue-500">
          Rhizobee Innovations Private Limited
        </a>{' '}
        and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500">
          https://rhizobee.com
        </a>{' '}
        has not complied with this Privacy Policy with respect to your Personal Information, you may contact the Data Protection Officer at{' '}
        <a href="mailto:contact@rhizobee.com" className="text-blue-500">
          contact@rhizobee.com
        </a>
        .
      </p>
    </div>
  )
}

export default PrivacyPolicy