import React, { useEffect } from 'react'
import PhotoshootPage from './PhotoshootPage'
import TextPage from './TextPage'
import GrayBoxText from './GrayBoxText'
import Ecomm from './Ecomm'
import UseApp from './UseApp'
import { Helmet } from 'react-helmet-async'

export const VirtualAiphotoshoot = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on every route change
  }, []);
  return (

    <>
      <Helmet>
        <title>AppliViu | Virtual Photoshoot</title>
        <meta name="description" content="Checkout AppliViu product and contact us for more details." />

        <meta
          name="keywords"
          content="Appliviu, Virtual Trial, Virtual Photoshoot, 3D Avatar, virtual photoshoot appliviu, E-commerce PhotoShoot, Virtual try-on, 
          virtual photoshoot app, virtual photoshoot ideas, ai virtual photoshoot, shutter virtual photoshoot, virtual assistant photoshoot, 
          Photoshoot Assistant virtually, modelverse, model verse, virtual photo meaning , adobe virtual photography, virtual photography meaning
          3d model verse, model photshoot virutally "
        />

        <link rel="canonical" href="/virtualAiPhotoshoot" />

      </Helmet>

      <div>
        <PhotoshootPage />
        <TextPage />
        <GrayBoxText />
        <Ecomm />
        <UseApp />
      </div>
    </>


  )
}

export default VirtualAiphotoshoot;