import React from 'react'
import Carousel from './Carousel'
import Introduction from './Introduction'
import AppliviuVP from './AppliviuVP'
import Appliviu1 from './AppliviuVT'
import Briefpage from './Briefpage'
import WhyBetter from './WhyBetter'
import TryOut from './TryOut'
import MobileVP from './AppliviuVPMobile'
import MobileVT from './AppliviuVTMobile'
import Testimonial from './Testimonial'
import { Helmet } from 'react-helmet-async'

export const LandingPage = () => {
  let slides = [
    require("../../images/2.png"),
    require("../../images/5.png"),
    require("../../images/3.png"),
    require("../../images/6.png"),
    require("../../images/1.png"),
    require("../../images/4.png"),
  ];


  let slides2 = [
    require("../../images/20.png"),
    require("../../images/50.png"),
    require("../../images/30.png"),
    require("../../images/60.png"),
    require("../../images/10.png"),
    require("../../images/40.png"),
  ];

  return (
    <>
      <Helmet>
        <title >AppliViu | Virtual Photoshoot | Try Clothes Virtually</title>
        <meta name='description' content='AppliViu provides AI-driven virtual photoshoot solution where AI algorithm works to 
        render the cloth image onto a model. It had large model library for both Men & Women.'/>

        <meta
          name="keywords"
          content="Appliviu, Virtual Trial, Virtual Photoshoot, 3D Avatar, virtual photoshoot appliviu, E-commerce PhotoShoot, Virtual try-on, 
          virtual photoshoot app, virtual photoshoot ideas, ai virtual photoshoot, shutter virtual photoshoot, virtual assistant photoshoot, 
          Photoshoot Assistant virtually, modelverse, model verse, virtual photo meaning , adobe virtual photography, virtual photography meaning
          3d model verse, model photshoot virutally "
        />

        <link rel="canonical" href="https://www.appliviu.com/"/>
      </Helmet>

      <div className="w-[97%] pt-1.5 max-w-[1390px] lg:pt-5 m-auto hidden lg:block">
        <Carousel slides={slides} />
      </div>

      <div className="w-[97%] pt-1.5 max-w-[1390px] lg:pt-5 m-auto block lg:hidden">
        <Carousel slides={slides2} />
      </div>


      <Introduction />
      <MobileVP />
      <MobileVT />
      <AppliviuVP />
      <Appliviu1 />
      <Briefpage />
      <WhyBetter />
      <TryOut />
      <Testimonial />
    </>
  )
}
