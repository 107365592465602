import React from "react";
import mobile from "../../images/saree.png";

const Personalized = () => {
  return (
    <div>
      <div className=" ml-8 mb-10 mt-16 md:ml-40 lg:ml-28 lg:mb-0 lg:mt-24">
        <h1 className="text-semibold text-4xl md:text-5xl">Personalised Avatar</h1>
      </div>
      <div className="flex flex-col lg:flex-row justify-evenly items-center ">
        <div className="flex-col space-y-8 md:mt-4 mx-8 lg:ml-28 lg:space-y-[80px] ">
          <div className="border-8 border-l-[#C5DCCA] border-y-0 border-r-0 max-w-sm">
            <p className="text-xl pl-5">
              Virtual fitting room suggesting true size for shoppers.
            </p>
          </div>
          <div className="border-8 border-l-[#C5DCCA] border-y-0 border-r-0 max-w-sm">
            <p className="text-xl pl-5 py-2">
              Explore various clothes on your 3d avatars
            </p>
          </div>
          <div className="border-8 border-l-[#C5DCCA] border-y-0 border-r-0 max-w-sm">
            <p className="text-xl pl-5 py-5">Convenient and fun for shoppers</p>
          </div>
        </div>
        <div className="my-6">
          <img className="h-[350px] lg:h-[480px] lg:w-[420px] " src={mobile} alt="sare" />
        </div>
        <div className="flex-col space-y-8 md:mr-10 lg:mr-28 lg:space-y-[80px] text-right">
        <div className="border-8 border-r-[#C5DCCA] md:mt-8 border-y-0 border-l-0 max-w-sm">
            <p className="text-xl pl-6 lg:pl-0 pr-5">
            Obtain a new identity for the company and enhance customer service.
            </p>
          </div>
          <div className="border-8 border-r-[#C5DCCA] border-y-0 border-l-0 max-w-sm">
            <p className="text-xl pl-6 lg:pl-0 pr-5 py-2">
            Shoppers can make efficient purchases
            </p>
          </div>
          <div className="border-8 border-r-[#C5DCCA] border-y-0 border-l-0 max-w-sm">
            <p className="text-xl pr-5 py-2">
            No stress about cloth size and appearance
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Personalized;
