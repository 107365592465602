import React from 'react'
import img1 from "../../images/Group 18.png"
import img2 from "../../images/Rectangle 736.png"
import img from '../../images/Vector.png';
import vid1 from '../../video/Sequence 01_88.mp4'

const Briefpage = () => {
  return (
    <div className='hidden lg:block'>
      <div className='flex relative mt-6 '>
        <div className='absolute '>
          <div className="px-20 py-14 relative">
            <img className='pl-[250px] w-full h-[580px]' src={img1} alt="img" />
            <video className='absolute top-[6.7rem] left-[10.38%] w-full h-[375.5px] ' src={vid1} autoPlay loop muted/>
          </div>
        </div>

        <div className='flex w-full'>
          <div className='mt-[33rem] mb-20 -z-20'>
            <img className='w-[50rem] h-[4rem]' src={img2} alt="img" />
          </div>
          <div className="ml-[45%] pt-24">
            <img src={img} alt="yellow" className="w-14 h-[400px]" />
          </div>

        </div>



      </div>
    </div>
  )
}

export default Briefpage
