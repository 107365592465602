import React from "react";
import img2 from "../../images/email.png";
import img1 from "../../images/company.png";


const Address = () => {
  return (
    <div className="flex flex-col-reverse lg:flex-row justify-evenly gap-8 mx-6 lg:mx-28 lg:mb-28 mb-10">

          <div className="lg:w-[38rem] h-[18rem] md:h-[15rem] lg:h-[15rem] bg-[#fafafa] shadow-xl shadow-gray-400 p-6">
            <div className="flex justify-centre items-center ">
              <img className="w-14 m-3 " src={img1} alt="img" />
              <h1 className="text-2xl font-bold">Address</h1>
            </div>
            <div className=" border-4 border-l-yellow-400 border-y-0 border-r-0 pl-3">
            <p className="text-medium">
            <span className="text-lg font-semibold">
            RHIZOBEE INNOVATIONS PRIVATE LIMITED <br />
            </span>
            RZ F-1/252 Mahavir Enclave 
            Palam Colony, Delhi,<br />
            South West Delhi, Delhi, India, 110045
      
            </p>
            </div>
          </div>

          <div className=" lg:w-[38rem] h-[15rem] bg-[#fafafa] shadow-xl shadow-gray-400 pt-14 lg:pt-0 p-6">   
            <div className="flex justify-centre items-center ">
              <img className="w-14  m-3" src={img2} alt="img" />
              <h1 className="text-2xl font-bold">Email</h1>
            </div>
            <div className=" border-4 border-l-yellow-400 border-y-0 border-r-0 pl-3">
              <p className="text-xl font-semibold">
              contact@rhizobee.com
              </p>
            </div>
          </div>
        </div>
  );
};

export default Address;
