import React from 'react'

const Terms = () => {
  return (
    <div className='mt-5 p-4'>
      <p>This policy is effective 17.09.2023</p>
      <h1 className='font-semibold my-2'>Terms and Condition</h1>
      <p className="mb-4">
        Please read these Terms and Conditions (Terms) carefully before proceeding with using or accessing this website{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>
        , and other related apps and software (collectively and individually, the Site or Platform) made available by us.
      </p>

      <p className="mb-4">
        <strong>THESE TERMS WILL GOVERN YOUR ACCESS AND USE OF THE SITE. IF YOU DO NOT AGREE WITH THE TERMS, YOU MUST NOT USE THE SITE.</strong>
      </p>

      <h1 className='font-semibold my-2'>Agreement of Terms</h1>
      <p className="mb-4">
        By accessing or using the Site or by placing an order through the Site, you agree to accept all the Terms and Conditions and therefore have entered into an agreement with us, governing your use of the Site.
      </p>

      <p className="mb-4">
        In addition, when you use any current or future service(s) and/or product(s), you also will be subject to the guidelines and conditions applicable to such service(s) or business, including the Privacy Policy which sets out how Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        will use personal information you provide or will provide us. If there is any conflict between the Privacy Policy and these Terms, then the Terms will prevail.
      </p>

      <p className="mb-4">
        Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        may change these Terms of Use from time to time. Your continued access or use of the Site constitutes your acceptance of such changes. Your access and use of the Site will be subject to the current version of the Terms of Use, rules, and guidelines posted on the Site at the time of such use. Please regularly check the "Terms of Use" link on the home page of Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        to view the then-current terms. If you breach any of the Terms of Use, your license to access or use this Site shall automatically terminate.
      </p>
      <h1 className='font-semibold my-2'>License</h1>
      <p className="mb-4">
        Subject to your compliance with these Terms of Use, any applicable license agreement with Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        , and the law, you may access and use the Site. Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        remains the sole owner of all right, title, and interest in the Site and reserves all rights not expressly granted under these Terms of Use. Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        may modify, replace, or discontinue the Site or any part thereof at any time, for any reason, with or without notice, in Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>'s sole discretion. Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        provides the Site on an "as is" and "as available" basis.
      </p>

      <p className="mb-4">
        All content on this Site, including but not limited to Images, Footage, Music, and related metadata (collectively the Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        Content"), as well as the selection and arrangement of the Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        Content, are protected by copyright and other intellectual property laws and treaties. Any unauthorized use of any Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        Content violates such laws and these Terms of Use. Except as expressly provided herein or in a separate license agreement between you and Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        , Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        does not grant any express or implied permission to use the Site or any Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        Content. You agree not to copy, republish, frame, link to, download, transmit, modify, adapt, create derivative works based on, rent, lease, loan, sell, assign, distribute, display, perform, license, sublicense or reverse engineer the Site or any Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        Content.
      </p>

      <p className="mb-4">
        In addition, you agree not to use any data mining, robots or similar data and/or image gathering and extraction methods in connection with the Site or Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        Content.
      </p>

      <p className="mb-4">
        Unless you enter into a license agreement with Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        , you may not download, distribute, display, and/or copy any Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        Content.
      </p>

      <p>
        You may not remove any watermarks or copyright notices contained in the Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        Content.
      </p>
      <h1 className='font-semibold my-2'>License for Photo Usage</h1>
      <ul className="list-disc ml-20 mb-4">
        <li>
          a. The Website may offer photos, images, or other visual content (collectively referred to as "Photos") for use under specific license terms.
        </li>
        <li>
          b. Users may only use Photos in compliance with the license terms provided alongside each Photo. Unauthorized use of Photos is strictly prohibited.
        </li>
      </ul>



      <h1 className='font-semibold my-2'>Licensing Terms</h1>
      <ul className="list-disc ml-20 mb-4">
        <li>
          a. Each Photo may have specific licensing terms, which may include restrictions on usage, attribution requirements, and fees.
        </li>
        <li>
          b. These terms will be provided with each Photo and must be adhered to.
        </li>
        <li>
          c. Users are responsible for understanding and adhering to the licensing terms associated with each Photo they download or use.
        </li>
      </ul>
      <h1 className='font-semibold my-2'>License to use the Site / Platform</h1>
      <h1 className='font-semibold my-2'>Your Content (images)</h1>
      <p className="mb-4">
        For any image, footage, text, audio, or any other content that you upload or post to the Site (“Your Content”), you represent and warrant that:
      </p>

      <p className="mb-4">
        (i) You have all necessary rights to submit Your Content to the Site and grant the licenses set forth herein; (ii) Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        will not need to obtain licenses from any third party or pay royalties to any third party with respect to Your Content; (iii) Your Content does not infringe any third party's rights, including intellectual property rights and privacy rights; and (iv) Your Content complies with these Terms of Use and all applicable laws.
      </p>

      <p className="mb-4">
        By uploading Your Content, you grant Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        a limited, worldwide, non-exclusive, royalty-free license and right to copy, transmit, distribute, publicly perform and display (through all media now known or hereafter created), and make derivative works from Your Content for the purpose of allowing you to edit and display Your Content using the Site and archiving or preserving Your Content for disputes, legal proceedings, or investigations. The above licenses will continue unless and until you remove Your Content from the Site, in which case the licenses will terminate within a commercially reasonable period of time. Notwithstanding the foregoing, the license for legal archival/preservation purposes will continue indefinitely.
      </p>
      <ul className="list-disc ml-20 mb-4">
        <li>
          i. Infringes any third party's copyrights or other intellectual property rights or any right of publicity or privacy.
        </li>
        <li>
          ii. Contains any pornographic, defamatory, or otherwise unlawful or immoral content.
        </li>
        <li>
          iii. Exploits minors.
        </li>
        <li>
          iv. Depicts unlawful or violent acts.
        </li>
        <li>
          v. Depicts animal cruelty or violence towards animals.
        </li>
        <li>
          vi. Promotes fraudulent schemes or gives rise to a claim of deceptive advertising or unfair competition.
        </li>
        <li>
          vii. Violates any law, statute, or regulation.
        </li>
      </ul>
      <p className="mb-4">
        You may not use any Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        Content for any purpose without first obtaining a license to use such Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        Content. Any use of Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        Content by you shall be governed by the applicable license agreement separately entered into between you and Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>.
      </p>

      <p className="mb-4">
        Displaying and/or distributing to the public any watermarked or unlicensed Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        Content (whether incorporated into a derivative work or alone) constitutes copyright infringement.
      </p>

      <p className="mb-4">
        You understand that you, and not Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        , are solely responsible for all photos that you upload on our Platform.
      </p>

      <p>
        We do not own or have any rights to all images you uploaded to our Site. You will remain the owner of your content. If you do not own the images you uploaded to our Site, you acknowledge that you have rights to modify the photos, and therefore providing us rights to modify them as well.
      </p>

      <h1 className='font-semibold my-2'>Copyright and Ownership</h1>
      <ul className="list-disc ml-20 mb-4">
        <li>
          a. All Photos available on the Website are protected by copyright and are the intellectual property of Rhizobee Innovations Private Limited and/or{' '}
          <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://appliviu.com
          </a>{' '}
          /{' '}
          <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://rhizobee.com
          </a>{' '}
          or the respective content creators.
        </li>
        <li>
          b. Users do not acquire ownership of the Photos.
        </li>
        <li>
          c. Users must not remove or alter any copyright notices or watermarks present on the Photos.
        </li>
      </ul>
      <h1 className='font-semibold my-2'>Permitted Use</h1>
      <ul className="list-disc ml-20 mb-4">
        <li>
          a. Users may use Photos in accordance with the specific license terms. This may include personal or commercial use, depending on the license associated with each Photo.
        </li>
        <li>
          b. Users must not use Photos for any unlawful, defamatory, or harmful purposes.
        </li>
      </ul>
      <h1 className='font-semibold my-2'>Attribution</h1>
      <p>Users must provide appropriate attribution as specified in the license terms for each Photo, if required.</p>
      <h1 className='font-semibold my-2'>Restrictions</h1>
      <ul className="list-disc ml-20 mb-4">
        <li>
          a. Users may not sublicense, sell, or redistribute Photos without express permission.
        </li>
        <li>
          b. Users must not use Photos in a way that misrepresents the source or creator of the Photo.
        </li>
        <li>
          c. Users must not morph the images into obscene or nude images.
        </li>
      </ul>
      <h1 className='font-semibold my-2'>Termination</h1>
      <p>Rhizobee Innovations Private Limited and/or https://appliviu.com / https://rhizobee.com reserves the right to terminate a user's access to the Website and Photos in the event of a violation of these Terms or license terms.</p>
      <h1 className='font-semibold my-2'>Privacy Policy</h1>
      <p>Your use of the Website is also governed by our Privacy Policy, available on the Website.</p>
      <h1 className='font-semibold my-2'>Disclaimer</h1>
      <ul className="list-disc ml-20 mb-4">
        <li>
          a. Rhizobee Innovations Private Limited and/or{' '}
          <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://appliviu.com
          </a>{' '}
          /{' '}
          <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://rhizobee.com
          </a>{' '}
          provide Photos on an "as-is" basis. While we strive for accuracy and quality, we do not guarantee the accuracy, suitability, or availability of the Photos for any particular purpose.
        </li>
        <li>
          b. Rhizobee Innovations Private Limited and/or{' '}
          <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://appliviu.com
          </a>{' '}
          /{' '}
          <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://rhizobee.com
          </a>{' '}
          may provide after-sale services to the user/client; however, we do not guarantee any services in case the image fails in the quality check on the user’s e-commerce website or any other e-commerce website where the user may submit/upload the photos or otherwise.
        </li>
        <li>
          c. Once the order is processed, the user/client cannot change the model or poses. If the user/client does not like the model or poses, a new order must be placed and will be charged accordingly.
        </li>
        <li>
          d. You agree that once you have approved the Order, it becomes final and irrevocable unless consented by Rhizobee Innovations Private Limited and/or{' '}
          <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://appliviu.com
          </a>{' '}
          /{' '}
          <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://rhizobee.com
          </a>.
        </li>
      </ul>
      <h1 className='font-semibold my-2'>Governing Law</h1>
      <p>These Terms are governed by the laws of NCT of Delhi. Any disputes shall be subject to the exclusive jurisdiction of the courts in Delhi.</p>
      <h1 className='font-semibold my-2'>Changes to Terms</h1>
      <p className="mb-4">
        Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        reserve the right to modify these Terms at any time. Users will be notified of any changes, and continued use of the Website after changes are posted constitutes acceptance of the modified Terms.
      </p>
      <h1 className='font-semibold my-2'>Contact Information</h1>
      <p className="mb-4">
        For questions or concerns regarding these Terms, please contact us at:
      </p>

      <address className="mb-4">
        <strong>RHIZOBEE INNOVATIONS PRIVATE LIMITED</strong><br />
        RZ F-1/252, Mahavir Enclave, Palam Colony,<br />
        New Delhi, South West Delhi, Delhi-110045.
      </address>

      <p>
        Email Address: <a href="mailto:contact@rhizobee.com" className="text-blue-500">contact@rhizobee.com</a>
      </p>
      <h1 className='font-semibold my-2'>User Registration</h1>
      <ul className="list-disc ml-20 mb-4">
        <li>
          a. Users may be required to register an account on the Website to access certain features or to download Images. Registration information must be accurate and kept up to date.
        </li>
        <li>
          b. Users are responsible for maintaining the confidentiality of their account information and are liable for any activity conducted under their account.
        </li>
        <li>
          c. If users believe that their account information has been compromised, they should contact Rhizobee Innovations Private Limited and/or{' '}
          <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://appliviu.com
          </a>{' '}
          /{' '}
          <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://rhizobee.com
          </a>{' '}
          immediately.
        </li>
      </ul>
      <h1 className='font-semibold my-2'>User Conduct</h1>
      <p>Users must not engage in any activity that disrupts or interferes with the proper functioning of the Website, including but not limited to hacking, spamming, or distributing malware.</p>
      <h1 className='font-semibold my-2'>User Content</h1>
      <ul className="list-disc ml-20 mb-4">
        <li>
          a. Users may have the option to upload their modified images or content ("User Content") to the Website.
        </li>
        <li>
          b. By uploading User Content, users grant Rhizobee Innovations Private Limited and/or{' '}
          <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://appliviu.com
          </a>{' '}
          /{' '}
          <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://rhizobee.com
          </a>{' '}
          a non-exclusive, royalty-free license to use, display, and distribute the User Content in connection with the Website.
        </li>
        <li>
          c. Users retain ownership of their User Content and may remove it from the Website at any time.
        </li>
      </ul>
      <h1 className='font-semibold my-2'>Limitations</h1>

      <p>You agree that you shall not:</p>
      <ul className="list-disc ml-20 mb-4">
        <li>
          i. Engage in any conduct that shall constitute a violation of any law or that infringes the rights of Rhizobee Innovations Private Limited and/or{' '}
          <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://appliviu.com
          </a>{' '}
          /{' '}
          <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://rhizobee.com
          </a>{' '}
          or any third party.
        </li>
        <li>
        ii. Violate any applicable laws or regulations related to the access to or use of the Site, or engage in any activity prohibited by these Terms of Use.
        </li>
        <li>
          iii. Violate the rights of Rhizobee Innovations Private Limited and/or{' '}
          <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://appliviu.com
          </a>{' '}
          /{' '}
          <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://rhizobee.com
          </a>{' '}
          or any third party (including rights of privacy and publicity) or abuse, defame, harass, stalk, or threaten another.
        </li>
      </ul>
      <h1 className='font-semibold my-2'>Indemnification</h1>
      <p className="mb-4">
        You agree to defend, indemnify and hold harmless Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>
        , its subsidiaries, affiliates, licensors, employees, agents, third party information providers, Submitters and independent contractors against any claims, damages, costs, liabilities and expenses (including, but not limited to, reasonable attorneys' fees) arising out of or related to your conduct, your use or inability to use Site, your breach or alleged breach of the Website Terms of Use or of any representation or warranty contained herein, your unauthorized use of the Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        Content, or your violation of any rights of another.
      </p>
      <h1 className="font-semibold my-2">Termination of Service</h1>
      <p className="mb-4">
        Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        reserves the right to suspend, modify, or discontinue all or any part of the Website or the availability of Images at any time without prior notice.
      </p>

      <h1 className="font-semibold my-2">Third-Party Links</h1>
      <p className="mb-4">
        The Website may contain links to third-party websites or resources. Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        is not responsible for the content or availability of these external sites and does not endorse or warrant the accuracy of any information found there.
      </p>

      <h1 className="font-semibold my-2">Entire Agreement</h1>
      <p className="mb-4">
        These Terms constitute the entire agreement between users and Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        regarding the use of the Website and supersede any prior agreements or understandings.
      </p>

      <h1 className="font-semibold my-2">Severability</h1>
      <p className="mb-4">
        If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.
      </p>

      <h1 className="font-semibold my-2">Waiver</h1>
      <p className="mb-4">
        The failure of Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        to enforce any provision of these Terms shall not constitute a waiver of such provision or any other provision.
      </p>

      <h1 className="font-semibold my-2">User Responsibility for Backups</h1>
      <p className="mb-4">
        Users are solely responsible for creating backups of any modified Images or User Content. Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        are not responsible for any loss or damage to User Content.
      </p>

      <h1 className="font-semibold my-2">Notice of Copyright Infringement</h1>
      <p className="mb-4">
        Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        respect the intellectual property rights of others. If you believe that any content on the Website infringes your copyright, please contact us with a notice of copyright infringement, including the information required by applicable copyright laws.
      </p>

      <h1 className="font-semibold my-2">Changes to Images and Services</h1>
      <p className="mb-4">
        Rhizobee Innovations Private Limited and/or{' '}
        <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://appliviu.com
        </a>{' '}
        /{' '}
        <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
          https://rhizobee.com
        </a>{' '}
        reserves the right to modify or remove Images and services offered on the Website at any time without notice. This includes changes to licensing terms and availability.
      </p>
      <h1 className="font-semibold my-2">Dispute Resolution</h1>
<p className="mb-4">
  Any disputes arising out of or related to these Terms or the use of the Website shall be resolved through negotiation and, if necessary, through arbitration in accordance with the rules of Arbitration and Conciliation Act, 1996 as in force for the time being on the date that such notice is given. Both parties agree to abide by the decision of the arbitrator, which shall be final and binding.
</p>

<h1 className="font-semibold my-2">Government Regulations</h1>
<p className="mb-4">
  Users are responsible for compliance with all applicable government regulations and export controls related to the download and use of Images.
</p>

<h1 className="font-semibold my-2">Waiver of Class Action</h1>
<p className="mb-4">
  Users agree not to participate in or initiate any class actions or collective actions against Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  related to the use of the Website or the Images.
</p>

<h1 className="font-semibold my-2">Headings</h1>
<p className="mb-4">
  The section headings in these Terms are for convenience only and have no legal or contractual effect.
</p>

<h1 className="font-semibold my-2">Feedback and Suggestions</h1>
<p className="mb-4">
  Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  welcome user feedback and suggestions for improving the Website. By providing feedback, users grant Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  the right to use and implement such feedback without any obligation or compensation.
</p>

<h1 className="font-semibold my-2">Release of Liability</h1>
<p className="mb-4">
  Users acknowledge that Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  is not responsible for any disputes or conflicts that may arise between users regarding the use or modification of Images, and users release Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  from any claims, demands, or damages related to such disputes.
</p>

<h1 className="font-semibold my-2">User Verification</h1>
<p className="mb-4">
  Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  may require users to verify their identity or eligibility to download and modify certain Images, especially for age-restricted or premium content.
</p>

<h1 className="font-semibold my-2">User Reviews and Comments</h1>
<p className="mb-4">
  Users may have the option to post reviews, comments, or feedback on the Website. Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  reserves the right to moderate, edit, or remove any such content that violates these Terms or community guidelines.
</p>

<h1 className="font-semibold my-2">Third-Party Providers</h1>
<p className="mb-4">
  Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  may use third-party providers or services for various aspects of the Website. Users agree to comply with the terms and conditions of these third-party providers when using their services.
</p>

<h1 className="font-semibold my-2">Accessibility</h1>
<p className="mb-4">
  Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  are committed to ensuring the accessibility of its website to individuals with disabilities. Users with accessibility concerns or requests may contact{' '}
  <a href="mailto:contact@rhizobee.com" className="text-blue-500">
    contact@rhizobee.com
  </a>{' '}
  for assistance.
</p>
<h1 className="font-semibold my-2">Notifications</h1>
<p className="mb-4">
  Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  may send notifications, updates, or announcements to users via email, website notifications, or other means. Users may have the option to opt out of certain communications.
</p>

<h1 className="font-semibold my-2">Business Transfers</h1>
<p className="mb-4">
  In the event of a merger, acquisition, or sale of all or part of Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  , user information and data may be transferred as part of the transaction. Users will be notified of any such transfer.
</p>

<h1 className="font-semibold my-2">User Responsibilities</h1>
<p className="mb-4">
  Users are responsible for ensuring that their devices, software, and internet connections meet the necessary requirements to access and use the Website effectively.
</p>

<h1 className="font-semibold my-2">Security</h1>
<p className="mb-4">
  Users must take appropriate measures to protect their accounts and passwords, and they should immediately notify Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  of any unauthorized access or security breaches.
</p>

<h1 className="font-semibold my-2">No Legal Advice</h1>
<p className="mb-4">
  The information provided on the Website, including any guidance related to image usage or modification is for informational purposes only and does not constitute legal or professional advice. Users should seek legal or professional advice as needed.
</p>

<h1 className="font-semibold my-2">Amendments</h1>
<p className="mb-4">
  Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  reserves the right to amend or update these Terms and Conditions at any time. Users will be notified of changes through the Website. Continued use of the Website after changes are posted constitutes acceptance of the modified Terms.
</p>

<h1 className="font-semibold my-2">Electronic Signature</h1>
<p className="mb-4">
  By using the Website, users acknowledge and agree that their electronic acceptance of these Terms and Conditions constitutes a valid and binding agreement.
</p>

<h1 className="font-semibold my-2">No Agency</h1>
<p className="mb-4">
  Nothing in these Terms and Conditions creates or implies any agency, partnership, or employment relationship between users and Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  .
</p>

<h1 className="font-semibold my-2">User Conduct and Community Guidelines</h1>
<p className="mb-4">
  Users must adhere to community guidelines, which may be posted on the Website, governing acceptable behaviour, communication, and interactions within the user community.
</p>

<h1 className="font-semibold my-2">User Contributions</h1>
<p className="mb-4">
  Users may contribute content to the Website, such as comments, reviews, or modified Images. By contributing content, users grant Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  the right to use, display, and distribute that content on the Website.
</p>

<h1 className="font-semibold my-2">Inactive Accounts</h1>
<p className="mb-4">
  Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  reserves the right to deactivate or suspend user accounts that have been inactive for an extended period without notice.
</p>

<h1 className="font-semibold my-2">Data Collection and Privacy</h1>
<p className="mb-4">
  Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  may collect and process user data as described in our Privacy Policy, available on the Website. Users should review the Privacy Policy to understand how their information is collected, used, and protected.
</p>

<h1 className="font-semibold my-2">Reservation of Rights</h1>
<p className="mb-4">
  Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  reserves all rights not expressly granted in these Terms and Conditions, including the right to modify or discontinue the Website and Image services.
</p>

<h1 className="font-semibold my-2">No Partnership or Endorsement</h1>
<p className="mb-4">
  Nothing in these Terms and Conditions shall be construed as creating a partnership, joint venture, employment relationship, or endorsement between users and Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  .
</p>

<h1 className="font-semibold my-2">Electronic Communications</h1>
<p className="mb-4">
  Users consent to receive electronic communications from Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  and agree that such communications, including notices, disclosures, and agreements, satisfy any legal requirement that such communications be in writing.
</p>

<h1 className="font-semibold my-2">Captions</h1>
<p className="mb-4">
  The section captions used in these Terms and Conditions are for convenience and reference only and do not affect the interpretation of these Terms and Conditions.
</p>

<h1 className="font-semibold my-2">Third-Party Services and Links</h1>
<p className="mb-4">
  The Website may include links or integrations with third-party services or websites. Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  is not responsible for the content or actions of these third-party services, and users should review and agree to their respective terms and policies.
</p>

<h1 className="font-semibold my-2">Infringement Reporting</h1>
<p className="mb-4">
  Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  provides a mechanism for reporting alleged copyright or intellectual property rights infringement on the Website. Users can submit infringement reports as per the procedures outlined on the Website.
</p>

<h1 className="font-semibold my-2">Compliance with Laws</h1>
<p className="mb-4">
  Users agree to comply with all applicable laws and regulations while using the Website and downloading or modifying Images. Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  are not responsible for any violations of such laws by users.
</p>

<h1 className="font-semibold my-2">No Employment Relationship</h1>
<p className="mb-4">
  Nothing in these Terms and Conditions creates an employment, agency, or partnership relationship between users and Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  . Users are independent parties responsible for their own tax and legal obligations.
</p>

<h1 className="font-semibold my-2">Confidentiality</h1>
<p className="mb-4">
  Users agree not to disclose any confidential information received from Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  , including any non-public information related to the Website or its operations.
</p>

<h1 className="font-semibold my-2">Export Control</h1>
<p className="mb-4">
  Users acknowledge that they may be subject to export control laws and regulations and agree not to use or download Images in violation of such laws, including restrictions on exports to prohibited countries or individuals.
</p>

<h1 className="font-semibold my-2">Reservation of Modifications</h1>
<p className="mb-4">
  Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  reserve the right to modify these Terms and Conditions at any time. Users will be notified of such modifications, and continued use of the Website after changes are posted constitutes acceptance of the modified Terms and Conditions.
</p>

<h1 className="font-semibold my-2">Survival</h1>
<p className="mb-4">
  Provisions related to intellectual property rights, indemnification, limitations of liability, dispute resolution, and any other clauses intended to survive termination shall remain in effect after these Terms and Conditions have ended.
</p>

<h1 className="font-semibold my-2">Suspension or Termination of Accounts</h1>
<p className="mb-4">
  Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  reserves the right to suspend or terminate user accounts that violate these Terms and Conditions or engage in abusive or fraudulent behaviour on the Website.
</p>

<h1 className="font-semibold my-2">No Warranty of Availability</h1>
<p className="mb-4">
  Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  does not guarantee uninterrupted availability of the Website or Images and may temporarily or permanently suspend access without notice for maintenance or other reasons.
</p>

<h1 className="font-semibold my-2">No Reliance on Information</h1>
<p className="mb-4">
  Users acknowledge that any information or content obtained through the Website is used at their own risk, and Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  is not responsible for errors, inaccuracies, or omissions in such information.
</p>

<h1 className="font-semibold my-2">Consent to Receive Communications</h1>
<p className="mb-4">
  Users consent to receive communications from Rhizobee Innovations Private Limited and/or{' '}
  <a href="https://appliviu.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://appliviu.com
  </a>{' '}
  /{' '}
  <a href="https://rhizobee.com" className="text-blue-500" target="_blank" rel="noopener noreferrer">
    https://rhizobee.com
  </a>{' '}
  related to the Website and Images, including notifications, updates, and newsletters, unless they opt out as provided in these Terms and Conditions or through the Website.
</p>

<h1 className="font-semibold my-2">Language</h1>
<p className="mb-4">
  These Terms and Conditions are drafted in the English. If these Terms and Conditions are translated into other languages, the English version shall prevail in case of any discrepancies.
</p>
<h1 className="font-semibold my-2">Reservation of Rights and Remedies</h1>
<p className="mb-4">
  Rhizobee Innovations Private Limited and/or https://appliviu.com / https://rhizobee.com reserves all rights and remedies not expressly granted in these Terms and Conditions, which are cumulative and not exclusive.
</p>

<h1 className="font-semibold my-2">No Assignment by Users</h1>
<p className="mb-4">
  Users may not assign or transfer their rights or obligations under these Terms and Conditions to any third party without the prior written consent of Rhizobee Innovations Private Limited and/or https://appliviu.com / https://rhizobee.com.
</p>

<h1 className="font-semibold my-2">Force Majeure</h1>
<p className="mb-4">
  Rhizobee Innovations Private Limited and/or https://appliviu.com / https://rhizobee.com shall not be liable for any delay or failure in performance under these Terms and Conditions resulting from circumstances beyond its reasonable control, including acts of nature, war, terrorism, government regulations, or any other force majeure event.
</p>

<h1 className="font-semibold my-2">Warranties and Disclaimers</h1>
<p className="mb-4">
  Your use of the Site is at your own risk. The Site is provided by Rhizobee Innovations Private Limited and/or https://appliviu.com / https://rhizobee.com under these terms of use "as is" without warranty of any kind, either express, implied, statutory or otherwise. Rhizobee Innovations Private Limited and/or https://appliviu.com / https://rhizobee.com expressly disclaims any and all warranties of any kind, whether express or implied, to each and any service available from the Site, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, non-infringement, and any other warranty that might arise under any law. Without limiting the foregoing, Rhizobee Innovations Private Limited and/or https://appliviu.com / https://rhizobee.com makes no warranty that: (i) the Site will meet your requirements; (ii) access to the Site will be uninterrupted; (iii) the quality of the Site will meet your expectations; and (iv) any errors or defects in the site, services or materials will be corrected. Rhizobee Innovations Private Limited and/or https://appliviu.com / https://rhizobee.com makes no representations or warranties that the Site will be permitted in your jurisdiction, that any of Your Content submitted by you will be available through the Site or will be stored by the Site, that the Site will meet your needs, or that Rhizobee Innovations Private Limited and/or https://appliviu.com / https://rhizobee.com will continue to support any particular feature of the Site. To the extent any disclaimer or limitation of liability does not apply, all applicable express, implied, and statutory warranties will be limited in duration to a period of thirty (30) days after the date on which you first used the Site, and no warranties shall apply after such period.
</p>

<h1 className="font-semibold my-2">Effective Date</h1>
<p className="mb-4">
  These Terms and Conditions shall be effective as of 17.09.2023, which is the date they are posted on the Website.
</p>
    </div>
  )
}

export default Terms