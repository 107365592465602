import React from "react";
import meas from "../../images/Measurement.png";
const Purpose = () => {
  return (
    <div className="relative md:mx-10 mb-40 lg:mb-[5rem]">
      <div className="ml-10 lg:ml-28 ">
        <h1 className="text-extrabold text-5xl">Our Purpose</h1>
        <p className="text-semibold text-2xl mt-4 absolute">
          Save 80% expense on returns.
        </p>
      </div>
      <div className="mt-20 mx-5 mb-5 lg:mx-0 lg:mt-0 lg:ml-[34rem] lg:w-[48%] ">
        <img src={meas} alt="measure" />
      </div>
      <div className="border-8 border-l-[#C5DCCA] border-y-0 border-r-0 max-w-md absolute lg:top-[21rem] ml-7 lg:ml-28">
          <p className="text-2xl  pl-4 py-2">
          Option to select neck, bust, shoulder, height, and hip size to generate your very own model
          </p>
        </div>
    </div>
  );
};

export default Purpose;
