import React from "react";
import img1 from '../../images/Header Frame 283.png';
import img2 from '../../images/Header Frame 282.png';
import { Link } from "react-router-dom";
import img5 from "../../images/pinkStrip.png"
import img from '../../images/Vector.png';
import { Helmet } from "react-helmet-async";



const OurProducts = () => {

  const handleViewMoreClick = () => {
    window.scrollTo({
      // behavior: "smooth",
      top: 0
    });
  };

  return (

    <>
      <Helmet>
        <title>AppliViu | Products</title>
        <meta name="description" content="Checkout AppliViu product and contact us for more details." />

        <meta
          name="keywords"
          content="Appliviu, Virtual Trial, Virtual Photoshoot, 3D Avatar, virtual photoshoot appliviu, E-commerce PhotoShoot, Virtual try-on, 
          virtual photoshoot app, virtual photoshoot ideas, ai virtual photoshoot, shutter virtual photoshoot, virtual assistant photoshoot, 
          Photoshoot Assistant virtually, modelverse, model verse, virtual photo meaning , adobe virtual photography, virtual photography meaning
          3d model verse, model photshoot virutally "
        />

        <link rel="canonical" href="/ourProducts" />
      </Helmet>

      <div className="">
        <div className="relative mt-12 mb-10 lg:mt-20 lg:mb-32 ">


          <div className="mx-14 lg:mx-96  ">
            <div className=" pl-3 border-l-4 border-[#C5DCCA] ">
              <p className="text-2xl font-bold	lg:text-4xl">
                Our Products
              </p>
            </div>
          </div>

          <div className=" mt-8 mx-12 lg:mx-10 gap-10 sm:flex flex-col space-y-8 sm:space-y-0 sm:flex-row justify-center items-center  ">
            <div className=" bg-[#FCFCFC]  shadow-2xl shadow-gray-400 rounded-2xl max-h-full sm:h-[24rem] md:h-[26rem] 2xl:w-[25%] 2xl:h-full hover:scale-105 hover:shadow-xl hover: transition-transform duration-300	 ">
              <Link to="/virtualAiPhotoshoot" onClick={handleViewMoreClick}>
                <div className="text-md pt-6  px-6 text-justify ">
                  <img className="rounded-xl" src={img1} alt="" />
                </div>
                <p className="text-sm pb-4 2xl:pb-4 pt-4 px-6 mx-2 text-left ">
                  AppliViu <span className="font-bold	">Virtual Photoshoot </span>
                  solution renders apparel photos on model and provides
                  high-quality,
                  realistic images without the need of physical photoshoots.
                </p>
              </Link>
            </div>

            <div className=" bg-[#FCFCFC]  shadow-2xl shadow-gray-400 rounded-2xl max-h-full sm:h-[24rem] md:h-[26rem] 2xl:w-[25%] 2xl:h-full hover:scale-105 hover:shadow-xl hover: transition-transform duration-300 ">
              <Link to="/virtualTrial" onClick={handleViewMoreClick}>
                <div className="text-md pt-6  px-6 text-justify ">
                  <img className="rounded-xl" src={img2} alt="" />
                </div>
                <p className="text-sm pb-4 2xl:pb-4 pt-4 px-6 mx-2 text-left ">
                  AppliViu <span className="font-bold	">Virtual Trial </span>
                  solution uses cutting-edge
                  augmented reality,
                  which allows customers to
                  try clothes virtually in real-time.
                </p>
              </Link>
            </div>
          </div>

          <div className="absolute hidden lg:block mr-auto top-0 -z-10">
            <img src={img} alt="yellow" className=" w-14 " />
          </div>


          <div className=" absolute right-0 top-[12rem] w-[150px] lg:top-[7.8rem]  -z-10">
            <img src={img5} className=" h-[5rem]  lg:h-[23rem]" alt="strip" />
          </div>
        </div>

      </div>


    </>


  );
};

export default OurProducts;
