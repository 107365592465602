import React from "react";
import videobg from "../../video/hompepageVid.mp4"
import blue from "../../images/bgBlueStrip.png";
import yellow from '../../images/Vector.png'

const Appliviu3D = () => {
  return (
    <div>
      <div className="flex flex-col justify-center items-center lg:gap-20 lg:flex-row relative">
        <div>
        <div className="md:mx-10 md:mt-10 lg:h-[550px] lg:w-[700px] lg:mr-[75px] border-[25px] border-[#F5F3EF] lg:mt-7  overflow-hidden">
          <video className="lg:h-[650px] " src={videobg} autoPlay loop muted />
        </div>
          <div className="hidden lg:block bg-[#FFD9D9] h-[200px] w-[110px] absolute top-0 left-[690px] -z-10">
          </div>
          <div className="hidden lg:block absolute left-[300px] -z-10 top-[412px]">
            <img className='w-[500px] h-[200px]' src={blue} alt="blue" />
          </div>
        </div>
        <div className=" text-center mt-12 lg:mt-0 lg:mr-32">
        <div className="lg:hidden pb-8 border-dashed border-t-4 border-[#C5DCCA]"></div>
          <h1 className="font-semibold text-4xl md:text-5xl">AppliViu's</h1>
          <h1 className="font-normal text-4xl md:text-5xl mt-2"> 3D Virtual Try on</h1>
          <div className="lg:hidden pb-8 border-dashed border-b-4 border-[#C5DCCA]"></div>
        </div>
      </div>
        <div className="hidden lg:block ml-[1460px] absolute top-[200px]">
            <img src={yellow} alt="yellow" className="w-14 h-[400px]" />
        </div>
    </div>
  );
};

export default Appliviu3D;
