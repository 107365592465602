import React from "react";
import img1 from "../../images/number1.png"
import img2 from "../../images/number2.png"
import img3 from "../../images/number3.png"
import img4 from "../../images/number4.png"

const DemoCardsVP = () => {
   return (
      <div>
         <div className="flex flex-col space-y-20 mx-20 md:space-y-0 md:flex-row mt-32 mb-36 md:space-x-20 md:mx-32">

            <div className=" basis-1/4 relative bg-[#C5DCCA] shadow-2xl shadow-gray-400 rounded-xl">
               <div className="absolute -top-[25%] left-[25%]">
                  <img className="h-32 " src={img1} alt="" />

               </div>
               <p className="text-center mt-3 py-16 px-5">
                  Retailers capture images of
                  their clothing items and upload
                  them on the AppliViu application.
               </p>

            </div>

            <div className=" basis-1/4 relative bg-[#C5DCCA]  shadow-2xl shadow-gray-400 rounded-xl   ">
               <div className="absolute -top-[25%] left-[25%]">
                  <img className="h-32 " src={img2} alt="" />

               </div>
               <p className="text-center mt-5 py-16 px-5">
                  Choose a suitable model based on your specific requirements.
               </p>
            </div>

            <div className=" basis-1/4 relative bg-[#C5DCCA]  shadow-2xl shadow-gray-400 rounded-xl  ">
               <div className="absolute -top-[25%] left-[25%]">
                  <img className="h-32 " src={img3} alt="" />

               </div>
               <p className="text-center mt-2 py-16 md:py-20 px-5">
                  A model wearing
                  the uploaded apparel gets
                  generated
               </p>
            </div>

            <div className=" basis-1/4 relative bg-[#C5DCCA]  shadow-2xl shadow-gray-400 rounded-xl  ">
               <div className="absolute -top-[25%] left-[25%]">
                  <img className="h-32 " src={img4} alt="" />

               </div>
               <p className="text-center mt-3 py-16 px-5">
                  Download the generated image,
                  ready for seamless
                  upload onto the e-commerce platform.
               </p>
            </div>
         </div>
      </div>
   )
}

export default DemoCardsVP