import React from "react";
import img1 from "../../images/integrity.png";
import img2 from "../../images/focus.png";
import img3 from "../../images/innovation.png";
import img4 from "../../images/teamwork.png";
import img5 from "../../images/pinkStrip.png"

const CoreValues = () => {
  return (
    <div className="relative">
      <div className="bg-[#233545] h-20 lg:w-96 mx-16 my-10 lg:mx-[9rem] lg:mt-24 flex justify-center items-center  ">
        <h1 className="text-3xl lg:text-4xl font-light text-white">
          Our Core Values
        </h1>
      </div>
      <div className="flex flex-col space-y-5 mb-16 lg:mb-28 mx-14 lg:mx-20">
        <div className="flex flex-col lg:flex-row justify-evenly">

          <div className="flex-col max-w-[36rem]  mb-6 lg:mb-0">
            <div className="flex justify-centre items-center ">
              <img className="w-20" src={img1} alt="img" />
              <h1 className="text-xl font-medium">Integrity</h1>
            </div>
            <div className=" border-4 border-l-yellow-400 border-y-0 border-r-0">
              <p className="text-base pl-4 text-justify ">
                We hold oueselves to the highest standards of integrity. We
                belive that honesty, transparency, and ethical behavior are
                critical components ot our sucess, and we are committed to
                upholding these values in all aspects of our business.
              </p>
            </div>
          </div>

          <div className="flex-col max-w-[36rem] ">
            <div className="flex  justify-centre items-center ">
              <img className="w-20" src={img2} alt="img" />
              <h1 className="text-xl font-medium">Customer Focus</h1>
            </div>
            <div className="border-4 border-l-yellow-400 border-y-0 border-r-0">
              <p className="text-base pl-4 text-justify">
                We are committed to serving the needs and expectations of our
                customer while adding value to their commendations. Customer's
                satisfaction is our happiness, and prioritizing their needs is
                our ultimate objective, making us a customer-centric
                organisation.                           
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row  justify-evenly">

          <div className="flex-col max-w-[36rem] mb-6  lg:mb-0">
            <div className="flex justify-centre items-center ">
              <img className="w-20" src={img3} alt="img" />
              <h1 className="text-xl">Innovation</h1>
            </div>
            <div className="border-4 border-l-yellow-400 border-y-0 border-r-0">
              <p className="text-base pl-4 text-justify">
                We are always looking for prospects of contemporary sound
                concepts and novel applications which will ultimately help
                increase our product quality. Modern opportunities are always
                available around us, our duty is to find them and cultivate
                amongst them.
              </p>
            </div>
          </div>
          <div className="flex-col max-w-[36rem] ">
            <div className="flex justify-centre items-center ">
              <img className="w-20" src={img4} alt="img" />
              <h1 className="text-xl">Teamwork</h1>
            </div>
            <div className="border-4 border-l-yellow-400 border-y-0 border-r-0">
              <p className="text-base pl-4 text-justify">
                We believe in having trust and confidence within our team to
                deliver the best service to our customers. Our approach is to
                harness internal efficiency to find creative solutions to
                challenges with a shared commitment.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className=" absolute right-0 top-[14rem] w-[150px] lg:top-[6rem] lg:w-[200px] -z-10">
        <img src={img5} className=" h-[50rem] lg:h-[28rem]" alt="strip" />
      </div>
    </div>
  );
};

export default CoreValues;
