import React, { useState, useEffect } from "react";
import { FaRunning, FaAward} from "react-icons/fa";
import { RiTimerLine} from "react-icons/ri";

function Carousel({ slides }) {
  const [current, setCurrent] = useState(0);

  // Fixed title and description
  //const fixedTitle = "AppliViu";
  //const fixedDescription = "Evolving your virtual shopping experience";

  // Function to handle auto-slide change
  const nextSlide = () => {
    setCurrent((prevCurrent) => (prevCurrent + 1) % slides.length);
  };

  // Automatically change slide every 3 seconds
  useEffect(() => {
    const interval = setInterval(nextSlide, 3000); // 3 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className="overflow-hidden mt-1 w-full lg:h-[680px] lg:mt-1 relative">
        <div
          className={`flex transition ease-out duration-400`}
          style={{
            transform: `translateX(-${current * 100}%)`,
          }}
        >
          {slides.map((s, index) => (
            <img key={index} src={s} alt={`Slide ${index + 1}`} />
          ))}
        </div>

        {/* <div className="absolute top-[4.6rem] left-52 sm:top-[120px] sm:left-[25rem] lg:top-[150px] lg:left-[700px] lg:text-left">
          <h1 className="text-2xl sm:text-6xl lg:text-7xl  text-white lg:m-3">{fixedTitle}</h1>
          <h2 className="sm:text-2xl lg:text-4xl  text-white lg:p-3">{fixedDescription}</h2>
        </div>
          */}

        {/* <div className="absolute top-0 h-full w-full flex justify-between items-center text-white px-10 text-2xl">
          <button onClick={previousSlide}>
            <BsFillArrowLeftCircleFill />
          </button>
          <button onClick={nextSlide}>
            <BsFillArrowRightCircleFill />
          </button>
      
        </div> */}
      </div>
      <div className="py-1 flex justify-center gap-3 w-full">
        {slides.map((_, i) => (
          <div
            onClick={() => {
              setCurrent(i);
            }}
            key={"circle" + i}
            className={`rounded-full lg:w-[10px] lg:h-[10px] cursor-pointer ${i === current ? "bg-gray-200" : "bg-gray-100"
              }`}
          ></div>
        ))}

        <div className="block lg:hidden">
        <div className="bg-gray-300 w-[97%] h-20 lg:w-[900px] lg:h-28 left-[0.4rem] flex justify-evenly absolute 2xl:left-[37.2%] lg:left-[30.5%] lg:top-[590px]  lg:border-[10px] rounded-lg border-white ">          <div className="flex  justify-center gap-2 items-center">
            <div className="text-3xl lg:text-6xl text-green-400">
              <RiTimerLine />
            </div>
            <h1 className="text-xs lg:text-lg" >Cost and <br />time saving</h1>
          </div>
          <div className="flex lg:max-w-10 justify-center gap-2 items-center">
            <div className="text-3xl lg:text-5xl text-orange-500">
              <FaAward />
            </div>
            <h1 className="text-xs lg:text-lg">High-quality <br /> content.</h1>
          </div>
          <div className="flex lg:max-w-10 justify-center gap-2 items-center">
            <div className="text-3xl lg:text-5xl text-blue-500">
              <FaRunning />
            </div>
            <h1 className="text-xs lg:text-lg">Fast and <br /> Hassle-free</h1>
          </div>
        </div>

        </div>
      </div>
    </>
  );
}

export default Carousel;
