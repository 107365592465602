import React from "react";
import img from "../../images/Rectangle 733.png";
import { Link } from "react-router-dom";

const Appliviu2 = () => {

  const handleViewMoreClick = () => {
    window.scrollTo({
      // behavior: "smooth",
      top: 0
    });
  };

  return (
    <div className="hidden lg:block">
      <div className="flex flex-col lg:flex-row lg:mx-16 lg:mb-16 lg:py-10 lg:justify-end lg:gap-10 relative">
        <div className="lg:w-1/2 text-justify mx-10 mt-10 lg:px-4">
          <h1 className="text-4xl mb-2 lg:text-2xl  first-letter:mb-6"> AppliViu's</h1>
          <h1 className="text-4xl mb-2 lg:text-5xl first-letter:mb-6"> Virtual Photoshoot</h1>
          <h2 className="text-2xl mb-2 lg:text-3xl  text-gray-500 lg:mb-3">
            Say Goodbye to traditional
            Photoshoot</h2>
          <p className="text-lg pb-5  lg:pb-5 text-gray-500">
            Virtual Photoshoot(VP) Solution by AppliViu redefines the way of apparel
            photoshoots. It renders apparel photos on model and provides
            high-quality, realistic images without the need of physical
            photoshoots. This innovative technology not only saves businesses time
            and resources but also ensures visually appealing product displays.
            Experience the future of e-commerce with our seamless and efficient
            virtual photography solution.
          </p>
          <div className="flex justify-center lg:justify-start gap-5 lg:gap-[20px]">

            <Link to="/DemoVP" onClick={handleViewMoreClick}>

              <button class="text-medium w-[120px]  lg:w-[150px] border-2 bg-transparent hover:bg-gray-500 text-gray-700 font-semibold  hover:text-white py-2 px-4 border-gray-800 hover:border-transparent rounded-full">
                DEMO
              </button>
            </Link>
            <Link to="/virtualAiPhotoshoot" onClick={handleViewMoreClick}>
              <button class="text-medium  border-2 bg-transparent hover:bg-gray-500 text-gray-700 font-semibold  hover:text-white py-2 px-4 border-gray-800 hover:border-transparent rounded-full">
                VIEW MORE
              </button>
            </Link>
            <Link to="/plansVP" onClick={handleViewMoreClick}>
              <button class="text-medium  border-2 bg-transparent hover:bg-gray-500 text-gray-700 font-semibold  hover:text-white py-2 px-4 border-gray-800 hover:border-transparent rounded-full">
                VIEW PLANS
              </button>
            </Link>
          </div>
        </div>
        <div className=" pt-10 lg:pt-0 lg:mr-20">
          <img className="lg:h-[500px] lg:w-[450px]" src={img} alt="img" />
        </div>
      </div>
    </div>
  );
};

export default Appliviu2;
